import {
    Avatar,
    Text,
    HStack, VStack, Tooltip,
} from '@chakra-ui/react'
import {CoreMenu} from '@krfts/core-menu'
import {NavLink} from "react-router";
import {useAuthStore} from "@krfts/app-state";
import {handleSignOut} from "./data";
import {useQuery} from "@tanstack/react-query";
import {getUser} from "@krfts/app-auth-modal/data/auth-api";
import {useEffect, useState} from "react";
import {createDownloadURL} from "@krfts/core-utils";

export const UserMenu = () => {

    const authStore = useAuthStore()
    const [userPhoto, setUserPhoto] = useState(null)

    const {isPending, isError, data, error} = useQuery(
        {
            queryKey: ['user', authStore.currentUser.uid],
            queryFn: () => getUser(authStore.currentUser)
        })

    useEffect(() => {
        const getPhotoURL = async () => {
            setUserPhoto(await createDownloadURL(data.profile_picture.file))
        }

        if (!isPending && data) {
            if (data.profile_picture.file) {
                getPhotoURL()
            }
        }
    }, [data]);

    return (
        <HStack spacing={1}>

            <VStack spacing={0} color='gray.500'>
                <Tooltip label={ !isPending && data ? (data.credits.plan_credits + ' plan credits / ' + data.credits.purchased_credits + ' purchased credits') : ""} placement='bottom'>
                    <HStack spacing={1}>
                        <Text fontSize='xs'
                              fontWeight={900}>{!isPending && data ? data.credits.plan_credits + data.credits.purchased_credits : ""}</Text>
                        <Text fontSize='xs'>credits</Text>
                    </HStack>
                </Tooltip>
            </VStack>

            <CoreMenu
                menuButton={<Avatar boxSize="10" name={authStore.currentUser ? authStore.currentUser.email : ""} src={userPhoto}/>}
                menuItemsAboveDivider={[
                    <NavLink to="/account">My Account</NavLink>,
                    <NavLink to="/account?tab=dashboard">Plans & Billing</NavLink>
                ]}
                menuItemsBelowDivider={[
                    <Text onClick={() => handleSignOut(authStore)}>Sign Out</Text>
                ]}
            />

        </HStack>
    )
}