import {AspectRatio} from "@chakra-ui/react";
import {CoreModal} from "@krfts/core-modal";
import {GenerationEntry} from "@krfts/app-shared-components";
import {useProductsStore} from "../../state/products-store";
import {calculateAspectRatio} from "@krfts/core-utils";

export const GenerationDisplayModal = () => {

    const showGenerationsDisplayModal = useProductsStore((state) => state.showGenerationsDisplayModal);
    const setShowGenerationsDisplayModal = useProductsStore((state) => state.setShowGenerationsDisplayModal);

    const generation = useProductsStore((state) => state.selectedGeneration);

    const getSize = () => {
        if (generation) {
            const format = generation.format
            if (format === '1024x1024 (1.0)') return '2xl'
            if (format === '1280x768 (1.67)') return '4xl'
            if (format === '768x1280 (0.6)') return 'xl'
        } else {
            return '2xl'
        }
    }

    return (
        <CoreModal
            isOpen={showGenerationsDisplayModal}
            onCloseClicked={() => {
                setShowGenerationsDisplayModal(false)
            }}
            size={getSize()}
            title="View generation"
        >
            {generation ?
                <AspectRatio ratio={generation ? calculateAspectRatio(generation.format) : 1} w='100%' bg='gray.200'>
                <GenerationEntry generationId={generation.id} studio={false}/>
            </AspectRatio> : null
            }
        </CoreModal>
    )
}