import axios from "axios";
import {
    API_BASE_URL,
    ADMIN_DATA_DUMP_ENDPOINT,
    ADMIN_LATEST_GENERATIONS_ENDPOINT,
    ADMIN_LATEST_PRODUCTS_ENDPOINT
} from "@krfts/app-common";
import {useAuthStore} from "@krfts/app-state";

export const getDataDump = async (user) => {
    const idToken = await useAuthStore.getState().currentUser.getIdToken(true)
    try {
        const response = await axios.get(API_BASE_URL + ADMIN_DATA_DUMP_ENDPOINT, {
            headers: {
                'Authorization': `Bearer ${idToken}`
            }
        })
        return response.data;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
}

export const getLatestGenerations = async () => {
    const idToken = await useAuthStore.getState().currentUser.getIdToken(true)

    try {
        const response = await axios.get(API_BASE_URL + ADMIN_LATEST_GENERATIONS_ENDPOINT, {
            headers: {
                'Authorization': `Bearer ${idToken}`,
            }
        })
        return response.data;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
}

export const getLatestProducts = async () => {
    const idToken = await useAuthStore.getState().currentUser.getIdToken(true)

    try {
        const response = await axios.get(API_BASE_URL + ADMIN_LATEST_PRODUCTS_ENDPOINT, {
            headers: {
                'Authorization': `Bearer ${idToken}`,
            }
        })
        return response.data;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
}