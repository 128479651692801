import {
    Box,
    useBreakpointValue,
} from '@chakra-ui/react'
import {getLatestGenerations} from "../../data";

import {useQuery} from '@tanstack/react-query'
import {
    GridOptions
} from "@krfts/app-products-content/ui/product-details/product-data/product-generations-grid/grid-options";
import {
    ProductGenerationsGridEntry
} from "@krfts/app-products-content/ui/product-details/product-data/product-generations-grid/product-generations-grid-entry";
import Masonry from "react-responsive-masonry"

export const LatestGenerations = () => {

    const {isPending, isError, data, error} =
        useQuery(
            {
                queryKey: ['latestGenerations'],
                queryFn: getLatestGenerations
            })

    const columnsCount = useBreakpointValue({base: 2, md: 6})

    if (isPending) return null

    return (
        <>
            <Box as={Masonry} columnsCount={columnsCount} gutter='0px'>
                {
                    data.sort((a, b) => new Date(b.created_at._seconds * 1000) - new Date(a.created_at._seconds * 1000)).map((generation, i) => {
                        if (generation.image_generation_data.state !== "ERROR") {
                            return (
                                <ProductGenerationsGridEntry key={i} generation={generation}/>
                            )
                        }
                    })
                }
            </Box>
        </>
    )
}