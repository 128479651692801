const apiBaseUrl = (process.env.NODE_ENV === `development`) ? `http://127.0.0.1:5001/styleframe-c4e92/us-central1/api` : `https://api-2tnm4rez6q-uc.a.run.app`
// console.log(apiBaseUrl)

const apiVersion = 'v1'

export const API_BASE_URL = apiBaseUrl
export const PRODUCT_ENDPOINT = `/${apiVersion}/product`
export const PRODUCT_ALL_ENDPOINT = `/${apiVersion}/product/all`
export const USER_ENDPOINT = `/${apiVersion}/user`

export const USER_STRIPE_BILLING_SESSION_ENDPOINT = `/${apiVersion}/user/stripe-billing-session`
export const USER_STRIPE_CUSTOMER_SESSION_ENDPOINT = `/${apiVersion}/user/stripe-customer-session`
export const USER_STRIPE_CHECK_CUSTOMER_SUBSCRIPTION = `/${apiVersion}/user/stripe-subscription-status`
export const USER_STRIPE_CREATE_CHECKOUT_SESSION = `/${apiVersion}/user/stripe-subscription-checkout`
export const USER_STRIPE_CREATE_CREDIT_CHECKOUT_SESSION = `/${apiVersion}/user/stripe-credits-checkout`

export const GENERATION_ENDPOINT = `/${apiVersion}/generation`
export const GENERATIONS_FOR_PRODUCT_ENDPOINT = `/${apiVersion}/generation/for-product`
export const IMAGE_GENERATION_ENDPOINT = `/${apiVersion}/generation/image`
export const IMAGE_INPAINT_GENERATION_ENDPOINT = `/${apiVersion}/generation/image-inpaint`
export const VIDEO_GENERATION_ENDPOINT = `/${apiVersion}/generation/video`
export const VIDEO_CAMERA_MOTIONS_ENDPOINT = `/${apiVersion}/generation/video/camera-motions`

export const SCENE_DESCRIPTION_ENDPOINT = `/${apiVersion}/scene-description`

export const BETA_AUTH = `/${apiVersion}/beta/auth`
export const BETA_REGISTER = `/${apiVersion}/beta/register`

export const STATUS_ENDPOINT = `/${apiVersion}/status`

export const ADMIN_LATEST_GENERATIONS_ENDPOINT = `/${apiVersion}/admin-internal/latest-generations`
export const ADMIN_LATEST_PRODUCTS_ENDPOINT = `/${apiVersion}/admin-internal/latest-products`
export const ADMIN_DATA_DUMP_ENDPOINT = `/${apiVersion}/admin-internal/data-dump`