import {
    Box, Button,
    Center, Icon,
    Text, Tooltip, VStack,
    Radio, RadioGroup, Select, HStack, Stack
} from '@chakra-ui/react'
import {IoAlertCircleOutline} from "react-icons/io5";
import {LoadingIndicator} from "@krfts/core-ui-library";
import {Prompt} from "@krfts/core-ui-library";
import {useEffect, useState} from "react";
import {costs} from "@krfts/app-common";
import {getCameraMotions} from "@krfts/app-studio-content/data/studio-api";
import {useHasEnoughCredits} from "@krfts/app-hooks";

export const GenerationEntryVideo = ({
                                         imageURL,
                                         videoURL,
                                         videoPrompt,
                                         setVideoPrompt,
                                         selectedCameraMotion,
                                         setSelectedCameraMotion,
                                         selectedFrame,
                                         setSelectedFrame,
                                         onGenerateVideoClicked,
                                         isGenerating,
                                         isSubmitting,
                                         error
                                     }) => {

    const [cameraMotions, setCameraMotions] = useState([])

    const hasEnoughCredits = useHasEnoughCredits(costs.video)

    useEffect(() => {
        const fetchCameraMotions = async () => {
            const cameraMotions = await getCameraMotions()
            setCameraMotions(cameraMotions)
        }

        fetchCameraMotions()
    }, []);

    return (
        videoURL ?
            <Box
                position='absolute'
                top={0}
                w='100%'
                h='100%'
                as='video'
                muted
                autoPlay
                controls
                src={videoURL}
                bg='black'
                loop
            />
            :

            <Box boxShadow='inset 0 0 0 2000px rgba(0, 0, 0, 0.8)' bgImage={imageURL} bgSize='cover'
                 position='absolute'
                 top={0}
                 w='100%'
                 h='100%'
            >
                <Center h='100%' color='white'>
                    {isGenerating ?
                        <LoadingIndicator color='white' message='Generating video...'/>
                        :
                        <VStack spacing={10}>
                            <VStack gap={0}>
                                {error ? <Icon boxSize='50px' color='red.500'>
                                    <IoAlertCircleOutline/>
                                </Icon> : null}
                                <Text>
                                    {error ? 'There was an error generating video, please try again.' : ''}
                                </Text>
                            </VStack>

                            <VStack gap={{base: 4, md: 6}} px={{base: 6, md: 2}} mt='-25px'>
                                <Text align='center' fontSize={{base: 'xs', md: 'md'}} fontWeight={900}>Generate a video
                                    from the
                                    image:</Text>

                                <Stack direction={{base:'column', md:'column'}} gap={{base: 4, md: 6}}>
                                    <VStack>
                                        <Text align='center' fontSize={{base: 'xs', md: 'sm'}}>Select whether the image
                                            should be the first or last
                                            frame of the
                                            video:</Text>
                                        <RadioGroup onChange={setSelectedFrame} defaultValue='first' color='white'>
                                            <Stack direction={{base:'row', md:'row'}} spacing={{base:2, md:4}}>
                                                <Radio value='first'>
                                                    <Text fontSize={{base: 'xs', md: 'sm'}}>First frame</Text>
                                                </Radio>
                                                <Radio value='last'>
                                                    <Text fontSize={{base: 'xs', md: 'sm'}}>Last frame</Text>
                                                </Radio>
                                            </Stack>
                                        </RadioGroup>
                                    </VStack>

                                    <VStack px={32}>
                                        <Text fontSize={{base: 'xs', md: 'sm'}}>Select a camera motion:</Text>
                                        <Select bg='white' size={{base: 'xs', md: 'sm'}} color='black' onChange={(e) => setSelectedCameraMotion(e.target.value)}>
                                            {cameraMotions.map((motion, i) => (
                                                <option key={i}>{motion}</option>
                                            ))
                                            }
                                        </Select>
                                    </VStack>
                                </Stack>


                                <Prompt
                                    color='black'
                                    placeholder='Any addition details you want to include in the video (optional)'
                                    prompt={videoPrompt}
                                    setPrompt={setVideoPrompt}
                                    bg='white'
                                    size={{base:'xs', md: 'md'}}
                                />

                                <Tooltip
                                    label={hasEnoughCredits ?
                                        ''
                                        :
                                        "You don't have enough credits to generate a video. Please purchase more credits."
                                    }
                                    placement='top'
                                >
                                    <Box bg='transparent' border='4px' borderColor='white' rounded='full' p={0.5}
                                         w='60%'>
                                        <Button
                                            isDisabled={!hasEnoughCredits}
                                            border='2px'
                                            borderColor='white'
                                            color='white'
                                            w='100%'
                                            size={{base: 'md', md: 'lg'}}
                                            rounded='full'
                                            bg="#101214"
                                            _hover={{bgGradient: "linear(to-r, pink.500, purple.500)"}}
                                            onClick={onGenerateVideoClicked}
                                        >
                                            <Text bgGradient="linear(to-r, yellow.300, pink.300)" bgClip='text'
                                                  fontWeight={900}>IMAGINATE!</Text>
                                        </Button>
                                    </Box>
                                </Tooltip>
                            </VStack>
                        </VStack>}
                </Center>
            </Box>
    )
}