import { useState, useEffect } from "react";
import {hasEnoughCreditsForAction} from "@krfts/app-utils";

export const useHasEnoughCredits = (cost, trigger) => {
    const [hasEnoughCredits, setHasEnoughCredits] = useState(false);

    useEffect(() => {
        const checkCredits = async () => {
            const enoughCredits = await hasEnoughCreditsForAction(cost);
            setHasEnoughCredits(enoughCredits);
        };

        checkCredits();
    }, [cost, trigger]);

    return hasEnoughCredits;
};
