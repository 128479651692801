import {
    Stack,
    Center,
    Container,
    Text,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Tooltip,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    Badge,
    Spacer,
    Box, Square, Icon, HStack, Image as ChakraImage, VStack,
} from '@chakra-ui/react'
import {IoAdd, IoChevronDownOutline} from "react-icons/io5";
import {startNewTraining, updateProduct} from "@krfts/app-data"
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {CoreModal} from "@krfts/core-modal";
import {Metadata} from "../../../new-product-modal/metadata";
import {ModelImages} from "../../../new-product-modal/model-images";
import {SourceImages} from "../../../new-product-modal/source-images";
import {useCallback, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router";
import {useAuthStore} from "@krfts/app-state";
import {getUser} from "@krfts/app-auth-modal/data/auth-api";
import {costs} from "@krfts/app-common";
import {centerCropImage} from "@krfts/core-utils";
import Compressor from "compressorjs";
import {LoadingIndicator, Prompt} from "@krfts/core-ui-library";
import {StatusBadge} from "../product-state/status-badge";
import dayjs from "dayjs";
import {AssetDropzone} from "@krfts/core-asset-dropzone";
import {Product3DPreview} from "./product-3d-preview";
import {RemoveButton} from "@krfts/app-shared-components";
import x_rot from '../../../../assets/x_rot.png'
import y_rot from '../../../../assets/y_rot.png'
import {AttentionTo3dSlider} from "./attention-to-3d-slider";
import {AttentionToGuidanceSlider} from "./attention-to-guidance-slider";

export const ProductSettingsModal = ({showModal, setShowModal, product}) => {


    const [isCreating, setIsCreating] = useState(false);

    const [isSaving, setIsSaving] = useState(false);
    const [saveButtonText, setSaveButtonText] = useState("Save")

    const [productName, setProductName] = useState("");
    const [productImage, setProductImage] = useState(null);
    const [sourceImages, setSourceImages] = useState([]);
    const sourceImagesRef = useRef(new Map());
    const [productDimensions, setProductDimensions] = useState({width: 0, height: 0, depth: 0})

    const [guidanceImage, setGuidanceImage] = useState(null);

    const [defaultPrompt, setDefaultPrompt] = useState("")

    const [custom3DModel, setCustom3DModel] = useState(null)
    const [custom3DModelRotOffset, setCustom3DModelRotOffset] = useState({x: 0, y: 0, z: 0})

    const [attentionTo3d, setAttentionTo3d] = useState(0.55)
    const [attentionToGuidance, setAttentionToGuidance] = useState(8)

    const [trainings, setTrainings] = useState([])
    const [selectedTraining, setSelectedTraining] = useState(null)
    const [activeTraining, setActiveTraining] = useState(null)

    const [modelImageLeft, setModelImageLeft] = useState(null);
    const [modelImageRight, setModelImageRight] = useState(null);
    const [modelImageFront, setModelImageFront] = useState(null);
    const [modelImageBack, setModelImageBack] = useState(null);
    const [modelImageTop, setModelImageTop] = useState(null);

    const navigate = useNavigate()
    const queryClient = useQueryClient()

    /// Check if enough credits -- this needs to be put somewhere in common
    const currentUser = useAuthStore((state) => state.currentUser)
    const [hasEnoughCredits, setHasEnoughCredits] = useState(false)
    const {data} = useQuery(
        {
            queryKey: ['user', currentUser.uid],
            queryFn: () => getUser(currentUser)
        })
    useEffect(() => {
        if (data) {
            const totalCredits = data.credits.purchased_credits + data.credits.plan_credits
            setHasEnoughCredits(totalCredits >= costs.lora + costs.model)
        }
    }, [data]);
    /////////////////////////////

    const onFileDropped = (files, type) => {
        files.map((file) => {
            if (file.type.startsWith("image/")) {

                const reader = new FileReader();
                reader.addEventListener("load", function () {
                    const img = new Image();
                    img.onload = function () {
                        // Perform center cropping
                        const croppedCanvas = centerCropImage(img);

                        // Convert cropped image back to a Blob
                        croppedCanvas.toBlob((blob) => {
                            const croppedFile = new File([blob], file.name, {type: file.type});

                            // Compress the cropped image
                            new Compressor(croppedFile, {
                                quality: 0.8,
                                width: 2048,
                                convertTypes: ['image/png', 'image/webp', 'image/avif'],
                                convertSize: 500000,

                                success(compressedFile) {
                                    console.log("compression complete");

                                    // Prepare the final file
                                    const finalReader = new FileReader();
                                    finalReader.addEventListener("load", function () {
                                        const newFile = {
                                            src: finalReader.result,
                                            file: compressedFile,
                                        };

                                        // Handle the file based on the type (same logic as before)
                                        if (type === "data") {
                                            if (!sourceImagesRef.current.has(file.name) && sourceImagesRef.current.size < 5) {
                                                sourceImagesRef.current.set(file.name, newFile);
                                            }
                                            setSourceImages(Array.from(sourceImagesRef.current.values()));
                                        } else if (type === "metadata") {
                                            console.log("setting productImage:", newFile);
                                            setProductImage(newFile);
                                        } else if (type === "Left View") {
                                            setModelImageLeft(newFile);
                                        } else if (type === "Right View") {
                                            setModelImageRight(newFile);
                                        } else if (type === "Front View") {
                                            setModelImageFront(newFile);
                                        } else if (type === "Back View") {
                                            setModelImageBack(newFile);
                                        } else if (type === "Top View") {
                                            setModelImageTop(newFile);
                                        } else if (type === "guidance") {
                                            setGuidanceImage(newFile);
                                        }
                                    });

                                    if (compressedFile) finalReader.readAsDataURL(compressedFile);
                                },
                                error(err) {
                                    console.log(err.message);
                                },
                            });
                        });
                    };
                    img.src = reader.result;
                });

                if (file) reader.readAsDataURL(file);
            }
        });
    };

    const onSourceImageRemoved = (image) => {
        sourceImagesRef.current.delete(image.file.name);
        setSourceImages(Array.from(sourceImagesRef.current.values()));
    }

    const onStartNewTrainingClicked = async () => {
        setIsCreating(true)

        const modelImages = [
            modelImageLeft && modelImageLeft,
            modelImageRight && modelImageRight,
            modelImageFront && modelImageFront,
            modelImageBack && modelImageBack,
            modelImageTop && modelImageTop,
        ].filter(image => image)

        console.log("modelImages:", modelImages)

        const response = await startNewTraining(product, sourceImages, modelImages)

        console.log("startNewTraining response:", response)

        // should probably navigate to the newly created product
        setProductName("")
        setProductImage(null)
        setSourceImages([])
        sourceImagesRef.current = new Map()
        setIsCreating(false)
        setShowModal(false)
        setModelImageLeft(null)
        setModelImageRight(null)
        setModelImageFront(null)
        setModelImageBack(null)
        setModelImageTop(null)

        await queryClient.invalidateQueries({queryKey: ['products']})

    }

    const onSaveClicked = async () => {
        setIsSaving(true)

        const remappedAttentionToGuidance = 10 - attentionToGuidance

        await updateProduct(product.id,
            productName,
            productImage,
            productDimensions,
            defaultPrompt,
            activeTraining,
            custom3DModel,
            custom3DModelRotOffset,
            attentionTo3d,
            guidanceImage,
            remappedAttentionToGuidance)
        setIsSaving(false)

        queryClient.invalidateQueries({queryKey: ['products']})
        queryClient.invalidateQueries({queryKey: ['product', product.id],})

        setSaveButtonText("Saved!")
        setTimeout(() => {
            setSaveButtonText("Save")
        }, 1500)
    }

    const canSubmit = () => {
        if (!hasEnoughCredits) return true
        else return !((modelImageLeft || modelImageRight || modelImageFront || modelImageBack || modelImageTop) && productImage && productName)
    }

    const getFileFromUrl = async (url, name, defaultType = 'image/jpeg') => {
        const response = await fetch(url);
        const data = await response.blob();
        const filename = name + "." + defaultType.split("/")[1];
        return new File([data], filename, {
            type: data.type || defaultType,
        });
    }

    const getDisplayableFile = async (url, prefix, setter) => {
        const file = await getFileFromUrl(url, prefix)
        setter({
            src: url,
            file: file,
        })
    }

    // fetch and prefill data
    useEffect(() => {
        if (product) {
            setProductName(product.name)

            getDisplayableFile(product.product_image.url, "product_image", setProductImage)

            if (product.guidance_image) getDisplayableFile(product.guidance_image.url, "guidance_image", setGuidanceImage)
            if (product.attention_to_guidance) setAttentionToGuidance(10 - product.attention_to_guidance)

            if (product.product_dimensions) {
                setProductDimensions(product.product_dimensions)
            }

            if (product.default_prompt) {
                setDefaultPrompt(product.default_prompt)
            }

            if (product.trainings) {
                const trainingsAr = []
                const activeTraining = product.active_training
                Object.keys(product.trainings).map(key => {
                    const training = product.trainings[key]
                    const isActive = key === activeTraining
                    training.active = isActive
                    training.id = key
                    training.running = training.lora_training && training.lora_training.status === "RUNNING"
                    trainingsAr.push(training)
                    if (isActive) {
                        setSelectedTraining(training)
                    }
                    setActiveTraining(activeTraining)
                })

                setTrainings(trainingsAr)
            }

            if (product.product_3D_model) {
                getDisplayableFile(product.product_3D_model.url, "product_3D_model", setCustom3DModel)
            }

            if (product.product_3D_model_rot_offset) {
                setCustom3DModelRotOffset(product.product_3D_model_rot_offset)
            }

            if (product.attention_to_3D) {
                setAttentionTo3d(product.attention_to_3D)
            }
        }
    }, [product]);

    // fetch info
    useEffect(() => {
        if (selectedTraining && selectedTraining.id !== "New training configuration") {
            selectedTraining.model_images.forEach((image, i) => {
                if (i === 0) getDisplayableFile(image.url, "model_image_" + i, setModelImageLeft)
                if (i === 1) getDisplayableFile(image.url, "model_image_" + i, setModelImageRight)
                if (i === 2) getDisplayableFile(image.url, "model_image_" + i, setModelImageFront)
                if (i === 3) getDisplayableFile(image.url, "model_image_" + i, setModelImageBack)
                if (i === 4) getDisplayableFile(image.url, "model_image_" + i, setModelImageTop)
            })

            selectedTraining.source_images.forEach((image, i) => {
                getDisplayableFile(image.url, "source_image_" + i, (file) => {
                    sourceImagesRef.current.set(file.file.name, file)
                    setSourceImages(Array.from(sourceImagesRef.current.values()))
                })
            })
        }
    }, [selectedTraining]);

    const formatDate = (timestamp) => {
        const date = new Date(timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000)
        const formattedDate = dayjs(date).format('HH:mm MMM D, YYYY')
        if (formattedDate === 'Invalid Date') return 'New training configuration'
        else return formattedDate
    }

    const addTraining = () => {
        const newTraining = {...trainings.find((training) => training.active)}
        console.log("newTraining:", newTraining)
        newTraining.active = false
        newTraining.id = 'New training configuration'
        newTraining.created_at = ''
        newTraining.lora_training = null
        newTraining.model_3D_generation = null
        setTrainings([...trainings, newTraining])
        setSelectedTraining(newTraining)
    }

    const on3DDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0]; // Get the first dropped file

        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                const newFile = {
                    src: reader.result,
                    file: file,
                };
                // const base64URL = reader.result; // The Base64 URL of the GLB file
                setCustom3DModel(newFile); // Save it to state
            };
            reader.readAsDataURL(file); // Convert the file to Base64
        }
    }, []);

    return (
        <CoreModal
            isOpen={showModal}
            onCloseClicked={() => {
                setShowModal(false)
            }}
            size='4xl'
            title='Product Settings'
            buttonComponents={null}
        >

            <Center w='100%' h='100%'>
                <Container maxW="4xl" py={{base: '12', md: '12'}} bg='black' p={{base: 2, md: 8}}
                           boxShadow="dark-lg" color='white'>
                    <Stack direction='column' spacing="8">
                        <Metadata
                            name={productName}
                            onNameChange={setProductName}
                            onFileDrop={onFileDropped}
                            productImage={productImage}
                            onProductImageRemoved={setProductImage}
                            product={product}
                            productDimensions={productDimensions}
                            onProductDimensionsChange={setProductDimensions}
                        />
                        <Accordion w={{base: '100%', md: '100%'}} allowToggle px={{base: 1, md: 8}}>
                            <AccordionItem>
                                <h2>
                                    <AccordionButton>
                                        <Box as='span' flex='1' textAlign='left'>
                                            <Text fontSize='sm'>Advanced settings:</Text>
                                        </Box>
                                        <AccordionIcon/>
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <Stack direction='column' spacing="8">

                                        <Stack direction={{base: 'column', md: 'row'}} justifyItems='space-between'
                                               w='100%' spacing={{base: '4', md: '4'}}>

                                            <Stack direction='row' w='100%' justifyContent='center' spacing='8'>

                                                <Stack direction='column' w='100%' spacing={7}>
                                                    <Stack direction='column' w='100%' color='black'>
                                                        <Text color='white' fontSize='sm'>Default prompt:</Text>
                                                        <Prompt bg='white' prompt={defaultPrompt}
                                                                setPrompt={setDefaultPrompt}/>
                                                    </Stack>

                                                </Stack>

                                                <Stack direction='column' w='50%' color='black'>
                                                    <Text color='white' fontSize='sm'>Guidance image:</Text>
                                                    {guidanceImage ?
                                                        <Box>
                                                            <Box position='relative' boxSize='150px'>
                                                                <ChakraImage src={guidanceImage.src}/>
                                                                <RemoveButton position='absolute' top={-0.5} right={-0.5} onClick={() => {
                                                                    setGuidanceImage(false)
                                                                }}/>
                                                            </Box>
                                                        </Box>
                                                        :
                                                        <AssetDropzone onFileDrop={(files, assetType) => onFileDropped(files, assetType)}
                                                                       assetType='guidance' boxSize='150px'>
                                                            <Square size="150px" bg="transparent">
                                                                <Icon as={IoAdd} boxSize="10" color="black"/>
                                                            </Square>
                                                        </AssetDropzone>}
                                                    <Stack direction='column' w='100%' color='black'>
                                                        <Text color='white' fontSize='sm'>Attention to Guidance:</Text>
                                                        <Box w={{base: '100%', md: '100%'}}>
                                                            <AttentionToGuidanceSlider value={attentionToGuidance}
                                                                                       setValue={setAttentionToGuidance}/>
                                                        </Box>
                                                    </Stack>
                                                </Stack>

                                            </Stack>


                                            <Spacer/>

                                            <Stack direction='column' w={{base: '100%', md: '25%'}} color='black'>
                                                <Text color='white' fontSize='sm'>Custom 3D model:</Text>
                                                {custom3DModel ?
                                                    <Stack direction='column' spacing='4'>
                                                        <Center>
                                                            <Box position='relative' boxSize='150px' border='1px'
                                                                 borderColor='white'>
                                                                <RemoveButton zIndex={1999} position='absolute'
                                                                              top={-0.5}
                                                                              right={-0.5}
                                                                              onClick={() => {
                                                                                  setCustom3DModel(null)
                                                                              }}/>
                                                                <Product3DPreview glbURL={custom3DModel.src}
                                                                                  rotOffset={custom3DModelRotOffset}/>
                                                            </Box>
                                                        </Center>
                                                        <Center>
                                                            <HStack spacing={1}>
                                                                <Text fontSize='8pt'
                                                                      color='white'>ORIENTATION:</Text>
                                                                <HStack spacing={1}>
                                                                    <Button variant='ghost' size='xs' p={0}
                                                                            sx={{filter: 'brightness(75%)'}}
                                                                            _hover={{
                                                                                bg: 'none',
                                                                                filter: 'brightness(100%)'
                                                                            }}
                                                                            onClick={() => {
                                                                                setCustom3DModelRotOffset({
                                                                                    x: custom3DModelRotOffset.x + 1.5708,
                                                                                    y: custom3DModelRotOffset.y,
                                                                                    z: custom3DModelRotOffset.z
                                                                                })
                                                                            }}><ChakraImage src={x_rot}
                                                                                            boxSize='35px'/></Button>
                                                                    <Button variant='ghost' size='xs' p={0}
                                                                            sx={{filter: 'brightness(75%)'}}
                                                                            _hover={{
                                                                                bg: 'none',
                                                                                filter: 'brightness(100%)'
                                                                            }}
                                                                            onClick={() => {
                                                                                setCustom3DModelRotOffset({
                                                                                    x: custom3DModelRotOffset.x,
                                                                                    y: custom3DModelRotOffset.y + 1.5708,
                                                                                    z: custom3DModelRotOffset.z
                                                                                })
                                                                            }}><ChakraImage src={y_rot}
                                                                                            boxSize='35px'/></Button>
                                                                </HStack>
                                                            </HStack>
                                                        </Center>
                                                    </Stack>
                                                    :
                                                    <Center>
                                                        <AssetDropzone onFileDrop={on3DDrop} assetType='3d'
                                                                       boxSize='150px'>
                                                            <Square size="10" bg="bg.subtle">
                                                                <Icon as={IoAdd} boxSize="10" color="black"/>
                                                            </Square>
                                                        </AssetDropzone>
                                                    </Center>
                                                }

                                                <Stack direction='column' w='100%' color='black'>
                                                    <Text color='white' fontSize='sm'>Attention to 3D:</Text>
                                                    <Box w={{base: '100%', md: '100%'}}>
                                                        <AttentionTo3dSlider value={attentionTo3d}
                                                                             setValue={setAttentionTo3d}/>
                                                    </Box>
                                                </Stack>

                                            </Stack>

                                        </Stack>

                                        <Stack direction='column' color='black'>
                                            <Text color='white' fontSize='sm'>Training configurations:</Text>
                                            <Menu bg='white' size='sm'>
                                                <MenuButton as={Button} size={{base: 'lg', md: 'md'}}
                                                            rightIcon={<IoChevronDownOutline/>}>

                                                    {trainings.length > 0 ?
                                                        <Stack direction={{base: 'row', md: 'row'}}
                                                               spacing={{base: "0.5", md: "8"}} align='center'>
                                                            <Stack spacing={1}>
                                                                <Text align='left'
                                                                      fontSize='xs'>Training created
                                                                    at: {formatDate(selectedTraining.created_at)}</Text>
                                                                <Text align='left'
                                                                      fontSize='2xs'
                                                                      fontWeight={100}>ID: {selectedTraining.id}</Text>
                                                            </Stack>
                                                            <Spacer/>
                                                            {selectedTraining.id === activeTraining ?
                                                                <Badge colorScheme='green'>Active</Badge> : null}
                                                            {selectedTraining.running ?
                                                                <StatusBadge
                                                                    modelStatus={selectedTraining.model_3D_generation.status}
                                                                    loraStatus={selectedTraining.lora_training.status}/> : null}
                                                        </Stack> : null}
                                                </MenuButton>
                                                <MenuList>
                                                    {trainings.map((training, i) => {
                                                        return (<MenuItem key={i}
                                                                          onClick={() => setSelectedTraining(training)}>
                                                            <Stack direction='row' spacing="8" align='center'>
                                                                <Stack spacing={1}>
                                                                    <Text
                                                                        align='left'
                                                                        fontSize='xs'
                                                                    >
                                                                        Training created
                                                                        at: {formatDate(training.created_at)}
                                                                    </Text>
                                                                    <Text align='left'
                                                                          fontSize='2xs'
                                                                          fontWeight={100}
                                                                    >
                                                                        ID: {training.id}
                                                                    </Text>
                                                                </Stack>
                                                                <Spacer/>
                                                                {training.id === activeTraining ?
                                                                    <Badge
                                                                        colorScheme='green'>Active</Badge> : null}
                                                                {training.running ?
                                                                    <StatusBadge
                                                                        modelStatus={training.model_3D_generation.status}
                                                                        loraStatus={training.lora_training.status}/> : null}
                                                            </Stack>
                                                        </MenuItem>)
                                                    })}
                                                    <MenuDivider/>
                                                    <MenuItem
                                                        isDisabled={trainings.find((training) => training.id === "New training configuration")}>
                                                        <Tooltip
                                                            label={trainings.find((training) => training.id === "New training configuration") ?
                                                                "You can only have one unsaved training configuration" :
                                                                ""}>
                                                            <Text
                                                                size='xs'
                                                                onClick={() => {
                                                                    if (!trainings.find((training) => training.id === "New training configuration"))
                                                                        addTraining()
                                                                }}
                                                            >
                                                                + Add new training
                                                            </Text>
                                                        </Tooltip>
                                                    </MenuItem>
                                                </MenuList>
                                            </Menu>
                                        </Stack>

                                        <Center>
                                            <ModelImages
                                                modelImageLeft={modelImageLeft}
                                                setImage={onFileDropped}
                                                setModelImageLeft={setModelImageLeft}
                                                modelImageRight={modelImageRight}
                                                setModelImageRight={setModelImageRight}
                                                modelImageFront={modelImageFront}
                                                setModelImageFront={setModelImageFront}
                                                modelImageBack={modelImageBack}
                                                setModelImageBack={setModelImageBack}
                                                modelImageTop={modelImageTop}
                                                setModelImageTop={setModelImageTop}
                                                product={product}
                                                allowRemove={selectedTraining && selectedTraining.id === "New training configuration"}
                                            />
                                        </Center>

                                        <SourceImages
                                            sourceImages={sourceImages}
                                            onImageRemoved={onSourceImageRemoved}
                                            onFileDrop={onFileDropped}
                                            product={product}
                                            allowRemove={selectedTraining && selectedTraining.id === "New training configuration"}
                                        />

                                        <Center>
                                            <Stack direction='row' spacing="8" alignContent='center'>
                                                {selectedTraining && selectedTraining.id !== "New training configuration" && selectedTraining.id !== activeTraining ?
                                                    <Tooltip
                                                        label={selectedTraining.lora_training.status !== 'DONE' ? "You can only set a training as active if it's done training" : ""}>
                                                        <Button
                                                            size='sm'
                                                            bg='yellow.300'
                                                            onClick={() => setActiveTraining(selectedTraining.id)}
                                                            isDisabled={selectedTraining.lora_training.status !== 'DONE'}
                                                        >
                                                            Set as Active
                                                        </Button></Tooltip> : null}

                                                {selectedTraining && selectedTraining.id === "New training configuration" ?
                                                    <Button
                                                        size='sm'
                                                        bg='yellow.300'
                                                        _hover={{bg: 'yellow.100'}}
                                                        onClick={() => onStartNewTrainingClicked()}
                                                    >
                                                        Start a new Training
                                                    </Button> : null}
                                            </Stack>
                                        </Center>

                                    </Stack>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>

                        <Center>
                            <Button bg='yellow.300'
                                    isLoading={isSaving}
                                    _hover={{bg: 'yellow.100'}}
                                    size='sm' w='20%' onClick={() => onSaveClicked()}>{saveButtonText}</Button>
                        </Center>

                    </Stack>
                </Container>
            </Center>


        </CoreModal>
    )
}