import axios from "axios";
import {API_BASE_URL, STATUS_ENDPOINT} from "@krfts/app-common";

export const getStatus = async () => {
    try {
        const response = await axios.get(API_BASE_URL + STATUS_ENDPOINT)
        return response.data;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
}