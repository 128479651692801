import {Canvas} from "@react-three/fiber";
import {Product3dScene} from "./product-3d-scene";

export const Product3DView = ({glbURL}) => {
    return (
        <Canvas w='100%' h='100%' style={{background: "black"}} gl={{preserveDrawingBuffer: true}} linear flat
                resize={{scroll: false}}
        >
            <color attach="background" args={["black"]}/>
            <Product3dScene url={glbURL}/>

        </Canvas>
    );
};

