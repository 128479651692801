import {
    Box,
    Center,
    Heading,
    Stack,
} from '@chakra-ui/react'
import { useState} from "react";


import {DashboardMenu} from "./ui/dashboard-menu";
import {LatestGenerations} from "./ui/latest-generations";
import {LatestProducts} from "./ui/latest-products";
import {DataBoard} from "./ui/data-board";


export const DashboardContent = () => {

    const [selectedContent, setSelectedContent] = useState("home")

    const renderContent = () => {
        if (selectedContent === "home") {
            return <DataBoard/>
        } else if (selectedContent === "generations") {
            return <LatestGenerations/>
        } else if (selectedContent === "products") {
            return <LatestProducts/>
        }
    }

    return (
        <>
            <Box w="300px" bg="gray.900" color="white" fontSize="sm" overflow='auto' position='relative'
                 sx={{
                     '&::-webkit-scrollbar': {
                         width: '4px',
                     },
                     '&::-webkit-scrollbar-track': {
                         width: '6px',
                     },
                     '&::-webkit-scrollbar-thumb': {
                         background: "gray",
                         borderRadius: '24px',
                     }
                 }}
            >
                <Stack direction='column' px={8} py={8}  spacing={24}>
                    <Center>
                        <Heading fontSize='md'>Dashboard</Heading>
                    </Center>

                    <DashboardMenu setSelectedContent={setSelectedContent}/>
                </Stack>
            </Box>


            <Box bg='white' flex="1" p="6" overflow='auto'
                 sx={{
                     '&::-webkit-scrollbar': {
                         width: '4px',
                     },
                     '&::-webkit-scrollbar-track': {
                         width: '6px',
                     },
                     '&::-webkit-scrollbar-thumb': {
                         background: "gray",
                         borderRadius: '24px',
                     }
                 }}
                 position='relative'>

                {renderContent()}

            </Box>
        </>
    )
}