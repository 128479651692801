import {useRef, useEffect, useState, Children} from "react";
import {CameraControls, Outlines, PivotControls, useGLTF} from "@react-three/drei";
import {useStudioStore, useProduct3DViewStore} from "../../../../state";
import {Vector3, Quaternion, Euler, Vector2, Box3} from "three";
import {useFrame, useThree} from "@react-three/fiber";
import * as THREE from "three";

export const Product3dScene = ({url}) => {
    const gltf = useGLTF(url);
    const nodes = gltf.nodes
    const gltfScene = gltf.scene

    const meshRef = useRef()
    const pivotRef = useRef()
    const camControlsRef = useRef()

    const productPosRef = useRef({x: 0, y: 0, z: 0})
    const productRotRef = useRef({x: 0, y: 0, z: 0})

    const [productScaleFactor, setProductScaleFactor] = useState(1)

    const selectedProduct = useStudioStore((state) => state.selectedProduct)
    const lockedGeneration = useStudioStore((state) => state.lockedGeneration)

    const isTransforming = useProduct3DViewStore((state) => state.isTransforming)

    const shouldCaptureControlImages = useStudioStore((state) => state.shouldCaptureControlImages)
    const setShouldCaptureControlImages = useStudioStore((state) => state.setShouldCaptureControlImages)

    const controlImagesCaptured = useStudioStore((state) => state.controlImagesCaptured)
    const setControlImagesCaptured = useStudioStore((state) => state.setControlImagesCaptured)

    const setOutlineImage = useStudioStore((state) => state.setOutlineImage)
    const setDepthImage = useStudioStore((state) => state.setDepthImage)
    const setMaskImage = useStudioStore((state) => state.setMaskImage)

    const setObjectTransform = useStudioStore((state) => state.setObjectTransform)
    const setCameraTransform = useStudioStore((state) => state.setCameraTransform)

    const imageFormat = useStudioStore((state) => state.imageFormat)

    const shouldDisplayTranslateControls = useStudioStore((state) => state.shouldDisplayTranslateControls)
    const shouldDisplayRotateControls = useStudioStore((state) => state.shouldDisplayRotateControls)
    const shouldResetTransform = useStudioStore((state) => state.shouldResetTransform)
    const setShouldResetTransform = useStudioStore((state) => state.setShouldResetTransform)

    const setIsTransforming = useProduct3DViewStore((state) => state.setIsTransforming)

    const {camera, gl, scene, get} = useThree()

    const meshGroupRef = useRef()
    const resetCamera = () => {
        meshGroupRef.current.scale.set(1, 1, 1)
        meshGroupRef.current.position.set(0, 0, 0)

        if (selectedProduct.product_dimensions) {
            const box3 = new Box3().setFromObject(meshGroupRef.current)
            // console.log("prescale:", box3)

            const size = new Vector3();
            box3.getSize(size); // Size of the current bounding box
            // console.log("BoundingBox size:", size);

            // Step 4: Get Target Product Dimensions
            const targetDimensions = selectedProduct.product_dimensions; // Width, Height, Depth
            const targetWidth = targetDimensions.width > 0 ? targetDimensions.width : 1;
            const targetHeight = targetDimensions.height > 0 ? targetDimensions.height : 1;
            const targetDepth = targetDimensions.depth > 0 ? targetDimensions.depth : 1;

            // Step 5: Compute Scaling Factor (uniform scaling)
            const scaleX = targetWidth / size.x;
            const scaleY = targetHeight / size.y;
            const scaleZ = targetDepth / size.z;
            const scalingFactor = Math.min(scaleX, scaleY, scaleZ); // Use the smallest scaling to fit within box

            // Step 6: Apply Scaling
            meshGroupRef.current.scale.setScalar(scalingFactor); // Uniform scaling

            // Step 7: Recenter the Mesh
            const center = new Vector3();
            box3.getCenter(center); // Find center of original bounding box
            meshGroupRef.current.position.sub(center.multiplyScalar(scalingFactor)); // Adjust position to recenter based on scaled center

            // Step 8: Recalculate Bounding Box (Optional, for debugging or camera adjustment)
            const scaledBox3 = new Box3().setFromObject(meshGroupRef.current);

            const scaledSize = new Vector3();
            scaledBox3.getSize(scaledSize); // Size of the current bounding box
            // console.log("BoundingBox scaled size:", scaledSize);

            const _productScaleFactor = scaledSize.y;
            setProductScaleFactor(_productScaleFactor)

            // console.log("scaleFactor:", productScaleFactor)
            // console.log("scalingFactor:", scalingFactor)

            if (camControlsRef.current) camControlsRef.current.fitToBox(scaledBox3, true, {
                paddingTop: .5 * _productScaleFactor,
                paddingBottom: .5 * _productScaleFactor,
                paddingLeft: .5 * _productScaleFactor,
                paddingRight: .5 * _productScaleFactor
            })
        } else {
            const box = new Box3().setFromObject(meshGroupRef.current)
            if (camControlsRef.current) camControlsRef.current.fitToBox(box, true, {
                paddingTop: .5,
                paddingBottom: .5,
                paddingLeft: .5,
                paddingRight: .5
            })

            setProductScaleFactor(1)
        }
    }

    const [sceneGeometries, setSceneGeometries] = useState([])

    useEffect(() => {
        if (selectedProduct.product_3D_model_rot_offset) {
            const rotOffset = selectedProduct.product_3D_model_rot_offset

            meshGroupRef.current.setRotationFromAxisAngle(new THREE.Vector3(1, 0, 0), rotOffset.x); // X rotation
            meshGroupRef.current.rotateOnWorldAxis(new THREE.Vector3(0, 1, 0), rotOffset.y); // Y rotation
            meshGroupRef.current.rotateOnWorldAxis(new THREE.Vector3(0, 0, 1), rotOffset.z);
        } else {
            meshGroupRef.current.setRotationFromAxisAngle(new THREE.Vector3(1, 0, 0), 0); // X rotation
            meshGroupRef.current.rotateOnWorldAxis(new THREE.Vector3(0, 1, 0), 0); // Y rotation
            meshGroupRef.current.rotateOnWorldAxis(new THREE.Vector3(0, 0, 1), 0);
        }

        if (camControlsRef.current && sceneGeometries.length > 0) {
            resetCamera()
        }
    }, [sceneGeometries]);

    useEffect(() => {
        if (gltfScene) {
            const geometries = []
            gltfScene.traverse((child) => {
                if (child.isMesh) {
                    geometries.push(child.geometry)
                }
            })

            setSceneGeometries(geometries)
        }
    }, [gltfScene]);

    // Reset transforms
    useEffect(() => {
        if (pivotRef.current && shouldResetTransform) {
            const initialRotation = [0, 0, 0]
            const initialPosition = [0, 0, 0]
            pivotRef.current.matrix.makeRotationFromEuler(new Euler(...initialRotation))
            pivotRef.current.matrix.setPosition(...initialPosition)
            productPosRef.current = {x: 0, y: 0, z: 0}
            productRotRef.current = {x: 0, y: 0, z: 0}

            setTimeout(() => {
                resetCamera()
            }, 100)
            // resetCamera()

            setShouldResetTransform(false);
        }
    }, [shouldResetTransform]);

    const [imageState, setImageState] = useState({currentState: "initial", waitForNextFrame: false});

    useEffect(() => {
        camera.fov = 54 // around 35 equivalent
        camera.updateProjectionMatrix()
    }, [])


    const lockTransformsForIteration = () => {
        if (lockedGeneration && lockedGeneration.product_id === selectedProduct.id) {
            const rotArray = [
                lockedGeneration.image_generation_data.object_transform.rotation.x,
                lockedGeneration.image_generation_data.object_transform.rotation.y,
                lockedGeneration.image_generation_data.object_transform.rotation.z,
            ]
            const posArray = [
                lockedGeneration.image_generation_data.object_transform.position.x,
                lockedGeneration.image_generation_data.object_transform.position.y,
                lockedGeneration.image_generation_data.object_transform.position.z,
            ]

            pivotRef.current.matrix.makeRotationFromEuler(new Euler(...rotArray))
            pivotRef.current.matrix.setPosition(new Vector3(...posArray))

            productPosRef.current = {
                x: posArray[0],
                y: posArray[1],
                z: posArray[2],
            }

            productRotRef.current = {
                x: rotArray[0],
                y: rotArray[1],
                z: rotArray[2],
            }

            camControlsRef.current.setPosition(
                lockedGeneration.image_generation_data.camera_transform.position.x,
                lockedGeneration.image_generation_data.camera_transform.position.y,
                lockedGeneration.image_generation_data.camera_transform.position.z,
            )

            camControlsRef.current.setTarget(
                lockedGeneration.image_generation_data.camera_transform.target.x,
                lockedGeneration.image_generation_data.camera_transform.target.y,
                lockedGeneration.image_generation_data.camera_transform.target.z,
            )

            camControlsRef.current.rotateTo(
                lockedGeneration.image_generation_data.camera_transform.rotation.azimuth_angle,
                lockedGeneration.image_generation_data.camera_transform.rotation.polar_angle,
            )

            // camControlsRef.current.distance = lockedGeneration.image_generation_data.camera_transform.distance

            camControlsRef.current.setFocalOffset(
                lockedGeneration.image_generation_data.camera_transform.focal_offset.x,
                lockedGeneration.image_generation_data.camera_transform.focal_offset.y,
                lockedGeneration.image_generation_data.camera_transform.focal_offset.z,
            )

            gl.render(scene, camControlsRef.current.camera)
        }
    }

    useEffect(() => {

        if (lockedGeneration) {
            // console.log("adding lock listener")
            camControlsRef.current.addEventListener('sleep', lockTransformsForIteration)
        } else {
            // console.log("removing lock listener")
            camControlsRef.current.removeAllEventListeners('sleep')
        }

        lockTransformsForIteration()
    }, [lockedGeneration])


    const captureCanvas = () => {
        const originalSize = new Vector2()
        gl.getSize(originalSize)
        const originalPixelRatio = gl.getPixelRatio()

        if (imageFormat.toLowerCase() === "square") {
            gl.setSize(1024, 1024, false)
        } else if (imageFormat.toLowerCase() === "landscape") {
            gl.setSize(1280, 768, false)
        } else if (imageFormat.toLowerCase() === "portrait") {
            gl.setSize(768, 1280, false)
        }
        gl.setPixelRatio(1)
        gl.render(scene, camera)

        const dataURL = gl.domElement.toDataURL("image/png");

        gl.setSize(originalSize.x, originalSize.y, false);
        gl.setPixelRatio(originalPixelRatio);

        return dataURL;
    }

    const captureMask = () => {
        setMaskImage(captureCanvas());
        // console.log("Mask captured");
        setImageState({currentState: "outline", waitForNextFrame: true}) // Move to the next step
    };

    const captureOutline = () => {
        setOutlineImage(captureCanvas());
        // console.log("Outline captured");
        setImageState({currentState: "depthPrep", waitForNextFrame: true}); // Prep depth step next
    };

    // TODO: figure out the bbox axis to calculate depth from based on angle towards camera
    const prepareDepth = () => {
        // console.log("Preparing depth");
        // const bbox = new Box3().setFromObject(nodes.geometry_0);

        const bbox = new Box3().setFromObject(meshGroupRef.current);

        const bboxSize = new Vector3();
        bbox.getSize(bboxSize);

        const bboxCenter = new Vector3();
        bbox.getCenter(bboxCenter);

        // console.log("bboxSize:", bboxSize)
        // console.log("bboxCenter:", bboxCenter)

        const longestSide = Math.abs(Math.max(bboxSize.x, bboxSize.z));
        const camPos = camera.position;
        const dist = camPos.distanceTo(bboxCenter);

        // console.log("longest side:", longestSide)
        // console.log("dist to camera:", dist)

        camera.near = (dist - longestSide) * 0.95;
        camera.far = (dist + longestSide) * 1.05;

        // console.log("camera near:", camera.near)
        // console.log("camera far:", camera.far)

        camera.updateProjectionMatrix();
        setImageState({currentState: "depth", waitForNextFrame: true})
    };

    const captureDepth = () => {
        setDepthImage(captureCanvas());
        // console.log("Depth captured");
        camera.near = 0.01; // Reset original camera settings
        camera.far = 1000;
        camera.updateProjectionMatrix();
        setImageState({currentState: "done", waitForNextFrame: false})
    };

    const resetState = () => {
        // console.log("Resetting state");
        setImageState({currentState: "initial", waitForNextFrame: true})
        setShouldCaptureControlImages(false);
    };

    useFrame(() => {
        if (imageState.waitForNextFrame) {
            setImageState({
                ...imageState,
                waitForNextFrame: false
            })
            return
        }

        if (shouldCaptureControlImages && !controlImagesCaptured) {
            switch (imageState.currentState) {
                case "initial":
                    setImageState({currentState: "mask", waitForNextFrame: false})
                    setObjectTransform({
                        position: productPosRef.current,
                        rotation: productRotRef.current
                    })
                    const pos = new Vector3()
                    const target = new Vector3()
                    const focalOffset = new Vector3()
                    camControlsRef.current.getPosition(pos)
                    camControlsRef.current.getTarget(target)
                    camControlsRef.current.getFocalOffset(focalOffset)
                    setCameraTransform({
                        position: {
                            x: pos.x,
                            y: pos.y,
                            z: pos.z,
                        },
                        target: {
                            x: target.x,
                            y: target.y,
                            z: target.z,
                        },
                        rotation: {
                            azimuth_angle: camControlsRef.current.azimuthAngle,
                            polar_angle: camControlsRef.current.polarAngle,
                        },
                        focal_offset: {
                            x: focalOffset.x,
                            y: focalOffset.y,
                            z: focalOffset.z,
                        },
                        distance: camControlsRef.current.distance,
                    })
                    break;
                case "mask":
                    captureMask();
                    break;
                case "outline":
                    captureOutline();
                    break;
                case "depthPrep":
                    prepareDepth();
                    break;
                case "depth":
                    captureDepth();
                    break;
                case "done":
                    setControlImagesCaptured(true)
                    resetState();
                    break;
                default:
                    break;
            }
        }
    });


    useEffect(() => {
        if (camControlsRef.current) {
            camControlsRef.current.addEventListener('controlend', () => {
                // console.log(camControlsRef.current.distance)

                camControlsRef.current.setOrbitPoint(productPosRef.current.x, productPosRef.current.y, productPosRef.current.z)
            })
        }
    }, [camControlsRef]);

    const renderGeometries = () => {
        return (
            <group ref={(ref) => {
                if (ref) {
                    if (ref.children.length === sceneGeometries.length) {
                        meshGroupRef.current = ref
                    }
                }
            }}
            >
                {sceneGeometries.map((geometry, i) => (
                    <mesh key={i} geometry={geometry} position={[0, 0, 0]}>
                        {!shouldCaptureControlImages ?
                            <>
                                <meshBasicMaterial color="black"/>
                                <Outlines thickness={1} color="white"/>
                            </>
                            : null
                        }

                        {shouldCaptureControlImages && imageState.currentState === "mask" ? (
                            <meshBasicMaterial color="white"/>) : null}

                        {shouldCaptureControlImages && imageState.currentState === "outline" ? (
                            <>
                                <meshBasicMaterial color="black"/>
                                <Outlines thickness={1.5} color="white" />
                            </>
                        ) : null}

                        {shouldCaptureControlImages && imageState.currentState === "depth" ?
                            <meshDepthMaterial/> : null}
                    </mesh>
                ))}
            </group>
        )
    }

    return (
        <group>
            <CameraControls
                makeDefault
                ref={camControlsRef}
                enabled={!isTransforming}
                dollySpeed={0}
                truckSpeed={1.25}
                azimuthRotateSpeed={0.5}
                polarRotateSpeed={0.5}
            />

            <PivotControls
                ref={pivotRef}
                anchor={[0, -1, 0]}
                depthTest={false}
                lineWidth={2}
                scale={productScaleFactor}
                autoTransform={true}
                visible={true}
                disableScaling={true}
                disableRotations={!shouldDisplayRotateControls}
                disableAxes={!shouldDisplayTranslateControls}
                disableSliders={!shouldDisplayTranslateControls}
                activeAxes={
                    shouldDisplayTranslateControls ?
                        [shouldDisplayTranslateControls, !shouldDisplayTranslateControls, shouldDisplayTranslateControls]
                        :
                        [true, false, true]
                }
                onDragStart={() => setIsTransforming(true)}
                onDragEnd={() => {
                    setIsTransforming(false)
                    camControlsRef.current.setOrbitPoint(productPosRef.current.x, productPosRef.current.y, productPosRef.current.z)
                }}
                onDrag={(local) => {
                    // keeping track of transforms
                    const position = new Vector3()
                    const scale = new Vector3()
                    const quaternion = new Quaternion()
                    local.decompose(position, quaternion, scale)
                    productPosRef.current = {x: position.x, y: position.y, z: position.z}
                    const euler = new Euler().setFromQuaternion(quaternion)
                    productRotRef.current = {x: euler.x, y: euler.y, z: euler.z}
                }}
            >
                {renderGeometries()}
            </PivotControls>
        </group>
    );
}
