import {Box, Textarea} from "@chakra-ui/react";

export const Prompt = ({prompt, setPrompt, placeholder, bg, color, size}) => {
    return (
        <Box w='full'>
            <Textarea
                color={color ? color : 'white'}
                bg={bg ? bg : 'gray.700'}
                placeholder={placeholder}
                resize
                value={prompt}
                onChange={e => setPrompt(e.target.value)}
                size={size ? size : 'md'}
            />
        </Box>
    )
}