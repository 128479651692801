import {Badge, Center, Spinner, HStack } from "@chakra-ui/react";

export const StatusBadge = ({modelStatus, loraStatus, size}) => {

    let status
    if (modelStatus === 'RUNNING' || loraStatus === 'RUNNING') {
        status = 'RUNNING';
    } else if (modelStatus === 'DONE' && loraStatus === 'DONE') {
        status = 'DONE';
    } else if (modelStatus === 'ERROR' || loraStatus === 'ERROR') {
        status = 'ERROR'; // Optional: Handle other scenarios
    } else if (modelStatus === 'WAITING' && loraStatus === 'WAITING') {
        status = 'WAITING';
    } else if (modelStatus === 'WAITING' && loraStatus === 'DONE') {
        status = 'WAITING';
    } else if (modelStatus === 'DONE' && loraStatus === 'WAITING') {
        status = 'WAITING';
    }

    const getColor = () => {
        if (status === "IN_QUEUE" || status === "Waiting" || status === "WAITING") return "blue"
        if (status === "IN_PROGRESS" || status === "Generating" || status === "RUNNING") return "purple"
        if (status === "COMPLETED" || status === "Done" || status === "DONE") return "green"
        if (status === "Failed") return "red"
    }

    const getText = () => {
        if (status === "IN_QUEUE" || status === "Waiting" || status === "WAITING") return "WAITING"
        if (status === "IN_PROGRESS" || status === "Generating" || status === "RUNNING") return "TRAINING"
        if (status === "COMPLETED" || status === "Done" || status === "DONE") return "READY"
        if (status === "Failed") return "ERROR"
    }

    return (
        <Badge colorScheme={getColor()} fontSize={size ? size : 'xs'}>
            <Center h='100%'>
                {getText()}
                &nbsp;
                {status !== "DONE" ? <Spinner size='xs'/> : null}
            </Center>
        </Badge>
    )
}