import {
    Heading,
    HStack,
    Image,
    Stack,
    Text,
    Box,
    Divider,
    Button,
} from '@chakra-ui/react'
import {GenerationsCount} from "@krfts/app-shared-components"
import {createDownloadURL} from "@krfts/core-utils"
import {useEffect, useState} from "react"
import {ProductGenerationsGrid} from "./product-generations-grid";
import {ProductState} from "./product-state";
import {ProductSettingsModal} from "./product-settings-modal";
import {IoSettingsOutline} from "react-icons/io5";

export const ProductData = ({product}) => {

    const [productImageURL, setProductImageURL] = useState(null)
    const [showSettingsModal, setShowSettingsModal] = useState(false)

    useEffect(() => {
        const getProductImageURL = async () => {
            setProductImageURL(await createDownloadURL(product.product_image.file))
        }

        getProductImageURL()
    }, [])

    return (
        <>
            <Stack direction='column' spacing={4} maxH='3xl' w='100%'>

                <Stack direction={{base: 'column', md: 'row'}} spacing={{base: '6', lg: '12'}} px={4} pb={4}>
                    <HStack spacing={8} align="top" w='50%'>
                        <Image src={productImageURL} alt={product.name} boxSize={36}/>
                        <Stack spacing={{base: '2', md: '2'}}>
                            <Heading size="lg" fontWeight="medium">
                                {product.name}
                            </Heading>
                            <Text fontSize='xs' color='gray.600'>ID: {product.id}</Text>
                            <GenerationsCount product={product}/>

                            {/*<Spacer/>*/}
                            {/*<Tooltip label='Settings to change name, add/remove images & re-train the whole model'*/}
                            {/*         placement='top'>*/}
                                <Button
                                    mt={6}
                                    w='125px'
                                    color='white'
                                    size='xs'
                                    variant='outline'
                                    leftIcon={<IoSettingsOutline size={16}/>}
                                    _hover={{bg: 'whiteAlpha.300'}}
                                    onClick={() => setShowSettingsModal(true)}
                                >
                                    Settings
                                </Button>
                            {/*</Tooltip>*/}
                        </Stack>
                    </HStack>

                    <ProductState product={product}/>

                </Stack>

                <Divider/>

                <Box h='100%' overflow='auto'
                     sx={{
                         '&::-webkit-scrollbar': {
                             width: '4px',
                         },
                         '&::-webkit-scrollbar-track': {
                             width: '6px',
                         },
                         '&::-webkit-scrollbar-thumb': {
                             background: "gray",
                             borderRadius: '24px',
                         }
                     }}
                >

                    <ProductGenerationsGrid product={product}/>

                </Box>
            </Stack>
            <ProductSettingsModal showModal={showSettingsModal} setShowModal={setShowSettingsModal} product={product}/>
        </>
    )
}