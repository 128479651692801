import {Box, Spinner, Center} from '@chakra-ui/react'
import {useEffect, useState} from "react";
import {NewProductModal} from "./ui/new-product-modal";
import {ProductList} from "./ui/product-list";
import bg_image from './assets/bg_image.jpg'
import {getProducts} from "@krfts/app-data"
import {useParams} from "react-router";
import {ProductDetails} from "./ui/product-details";
import {LoadingIndicator} from "@krfts/core-ui-library";
import {GenerationDisplayModal} from "./ui/generation-display-modal";

import {useQuery} from '@tanstack/react-query'

export const ProductsContent = () => {

    const [showNewProductModal, setShowNewProductModal] = useState(false);

    let {id} = useParams();

    const {isPending, isError, data, error} = useQuery(
        {
            queryKey: ['products'],
            queryFn: getProducts,
            refetchInterval: (data) => {
                let shouldPoll = false
                if (data.state.data) {
                    data.state.data.forEach((product) => {
                        if (product.trainings) {
                            const activeTraining = product.active_training
                            if (product.trainings[activeTraining].lora_training.status !== 'DONE' || product.trainings[activeTraining].model_3D_generation.status !== 'DONE') {
                                shouldPoll = true;
                            }
                        } else {
                            if (product.lora_training.status !== 'DONE' || product.model_3D_generation.status !== 'DONE') {
                                shouldPoll = true;
                            }
                        }
                    });
                }
                if (shouldPoll) {
                    return 60000
                } else {
                    return false
                }
            }
        }
    )

    const renderContent = () => {
        if (isPending) {
            return (<LoadingIndicator/>)
        }

        if (id) {
            return <ProductDetails id={id}/>
        } else {
            return <ProductList products={data} setShowNewProductModal={setShowNewProductModal}/>
        }
    }

    return (
        <>
            <Box
                w='100%'
                h='100%'
                bgImage={bg_image}
                bgSize='cover'
                overflow='auto'
                position='relative'
                boxShadow='inset 0 0 0 2000px rgba(0, 0, 0, 0.7)'
            >
                {renderContent()}
            </Box>
            <NewProductModal showModal={showNewProductModal} setShowModal={setShowNewProductModal}/>
            <GenerationDisplayModal/>
        </>
    )
}