import {
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    Text,
} from '@chakra-ui/react'

export const AttentionTo3dSlider = ({value, setValue}) => {
    return(
        <Slider
            aria-label='attn-to-3d-slider'
            value={value || 0.55}
            onChange={(val) => setValue(val)}
            min={0.5} max={1} step={0.05}
        >
            <SliderTrack>
                <SliderFilledTrack bg='yellow.300' />
            </SliderTrack>
            <SliderThumb boxSize={5}>
                <Text align='center' color='black' fontSize={8} fontWeight={900}>{value}</Text>
            </SliderThumb>
        </Slider>
    )
}