import {Box,} from '@chakra-ui/react'
import {getLatestProducts} from "../../data";
import {useQuery} from '@tanstack/react-query'
import {ProductGrid} from "@krfts/app-products-content/ui/product-list/product-grid";

export const LatestProducts = () => {

    const {isPending, isError, data, error} =
        useQuery(
            {
                queryKey: ['latestProducts'],
                queryFn: getLatestProducts
            })

    if (isPending) return null

    return (
        <>
            <ProductGrid products={data}/>
        </>
    )
}