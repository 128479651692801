import { Badge, Box, Button, Divider, Heading, HStack, Icon, Stack, Text } from '@chakra-ui/react'


export const Stat = (props) => {
    const { label, value, delta, icon, ...boxProps } = props
    return (
        <Box bg="gray.600" borderRadius="md" boxShadow="sm" {...boxProps} color='white'>
            <Box px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                <Stack spacing={4}>
                    <HStack>
                        <Icon as={icon} boxSize="6"/>
                        <Text textStyle="sm" color="fg.muted">
                            {label}
                        </Text>
                    </HStack>
                    <HStack justify="space-between">
                        <Heading size={{ base: 'sm', md: 'xl' }}>{value}</Heading>
                    </HStack>
                </Stack>
            </Box>
        </Box>
    )
}