import {
    Avatar,
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Stack,
    StackDivider,
    Heading,
    VStack,
    Icon,
    Square,
    Text,
} from '@chakra-ui/react'
import {AssetDropzone} from "@krfts/core-asset-dropzone";
import {IoPencil} from "react-icons/io5";
import {getUser, updateUser} from "@krfts/app-auth-modal/data/auth-api";
import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {useAuthStore} from "@krfts/app-state";
import {centerCropImage} from "@krfts/core-utils";
import Compressor from "compressorjs";
import {createDownloadURL} from "@krfts/core-utils";
import {useQueryClient} from "@tanstack/react-query";

export const UserProfile = ({user}) => {

    const currentUser = useAuthStore((state) => state.currentUser);

    const [userName, setUserName] = useState("");
    const [userPhoto, setUserPhoto] = useState(null);
    const [isSaving, setIsSaving] = useState(false);

    const queryClient = useQueryClient();

    const {isPending, isError, data, error} = useQuery(
        {
            queryKey: ['user', currentUser.uid],
            queryFn: () => getUser(currentUser)
        })

    useEffect(() => {
        const getPhotoURL = async () => {
            setUserPhoto(await createDownloadURL(data.profile_picture.file))
        }

        if (!isPending && data) {
            if (data.name)
                setUserName(data.name);
            if (data.profile_picture) {
                getPhotoURL()
            }
        }
    }, [data]);

    const onFileDropped = (files) => {
        files.map((file) => {
            if (file.type.startsWith("image/")) {

                const reader = new FileReader();
                reader.addEventListener("load", function () {
                    const img = new Image();
                    img.onload = function () {
                        // Perform center cropping
                        const croppedCanvas = centerCropImage(img);

                        // Convert cropped image back to a Blob
                        croppedCanvas.toBlob((blob) => {
                            const croppedFile = new File([blob], file.name, {type: file.type});

                            // Compress the cropped image
                            new Compressor(croppedFile, {
                                quality: 0.8,
                                width: 2048,
                                convertTypes: ['image/png', 'image/webp', 'image/avif'],
                                convertSize: 500000,

                                success(compressedFile) {

                                    // Prepare the final file
                                    const finalReader = new FileReader();
                                    finalReader.addEventListener("load", function () {
                                        setUserPhoto(finalReader.result);
                                    });

                                    if (compressedFile) finalReader.readAsDataURL(compressedFile);
                                },
                                error(err) {
                                    console.log(err.message);
                                },
                            });
                        });
                    };
                    img.src = reader.result;
                });

                if (file) reader.readAsDataURL(file);
            }
        });
    };

    const handleSave = async () => {
        setIsSaving(true);
        await updateUser(currentUser, userName, userPhoto)
        setIsSaving(false);

        await queryClient.invalidateQueries({
            queryKey: ['user', currentUser.uid]
        })
    }

    return (
        <VStack spacing={14} w='100%' align='start'>
            <Heading size='md'>Your Profile</Heading>

            <Stack spacing="5" divider={<StackDivider/>} w='60%'>
                <FormControl id="name">
                    <Stack
                        direction={{base: 'column', md: 'row'}}
                        spacing={{base: '1.5', md: '8'}}
                        justify="space-between"
                    >
                        <FormLabel variant="inline">Name</FormLabel>
                        <Input maxW={{md: '3xl'}} value={userName} onChange={(e) => setUserName(e.target.value)} placeholder="Your name"/>
                    </Stack>
                </FormControl>
                <FormControl id="email">
                    <Stack
                        direction={{base: 'column', md: 'row'}}
                        spacing={{base: '1.5', md: '8'}}
                        justify="left"
                    >
                        <FormLabel variant="inline">Email</FormLabel>
                        {/*<Input type="email" maxW={{md: '3xl'}} defaultValue={user.email}/>*/}
                        <Text maxW={{md: '3xl'}} color='white'>{user.email}</Text>
                    </Stack>
                </FormControl>
                <FormControl id="picture">
                    <Stack
                        direction={{base: 'column', md: 'row'}}
                        spacing={{base: '1.5', md: '8'}}
                        justify="space-between"
                    >
                        <FormLabel variant="inline">Photo</FormLabel>
                        <Stack
                            spacing={{base: '3', md: '5'}}
                            direction={{base: 'column', sm: 'row'}}
                            width="full"
                            maxW={{md: '3xl'}}
                        >

                            <Box position='relative'>
                                <Avatar size="lg" name={user.email} position='absolute' src={userPhoto} />

                                <AssetDropzone onFileDrop={onFileDropped} bg='transparent'
                                               border={0} boxSize='65px'>
                                    <VStack spacing="1">
                                        <Square size="16" bg="bg.subtle" borderRadius="lg" bg='transparent'
                                                rounded='full' color="transparent"
                                                _hover={{color: "black", bg: "blackAlpha.400"}}>
                                            <Icon as={IoPencil} boxSize="6"/>
                                        </Square>
                                    </VStack>
                                </AssetDropzone>

                            </Box>

                        </Stack>
                    </Stack>
                </FormControl>
                <Flex direction="row-reverse">
                    <Button bg='yellow.300' _hover={{bg: 'yellow.100'}} isLoading={isSaving}
                            onClick={() => handleSave()}
                    >Save</Button>
                </Flex>
            </Stack>

        </VStack>
    )
}