import {
    VStack,
    HStack,
    Box,
    Text,
    Icon,
    Square,
    Stack, Center
} from '@chakra-ui/react'

import {AssetDropzone} from "@krfts/core-asset-dropzone";
import {ProductImage} from "./product-image";
import {RemoveButton} from "@krfts/app-shared-components";
import {IoAdd} from "react-icons/io5";
import {useEffect, useState} from "react";
import {LoadingIndicator} from "@krfts/core-ui-library";

export const SourceImages = ({sourceImages, onImageRemoved, onFileDrop, allowRemove}) => {
    const [isDropping, setIsDropping] = useState( false)

    const handleDrop = (files, assetType) => {
        setIsDropping( true)
        onFileDrop(files, assetType)
    }

    useEffect(() => {
        if (isDropping) setIsDropping(false)
    }, [sourceImages]);

    return (
        <VStack spacing={6}>
            <Stack direction={{base: 'column', md: 'row'}} spacing={4}>
                {sourceImages.map((image, i) => (
                    <Box position='relative' key={i}>
                        <ProductImage image={image} onImageRemoved={onImageRemoved} isDropping={isDropping}/>
                        {allowRemove ? <RemoveButton position='absolute' top={-0.5} right={-0.5} onClick={() => {
                            onImageRemoved(image)
                        }}/> : null}
                    </Box>
                ))}

                {isDropping ?
                    <Box boxSize={{base: '150px', md: '100px'}}>
                        <Center h='100%'>
                            <LoadingIndicator color='white' size='sm' thickness='2px'/>
                        </Center>
                    </Box>
                    :
                    null
                }

                {
                    sourceImages.length < 5 ?
                        <AssetDropzone onFileDrop={(files, assetType) => handleDrop(files, assetType)} assetType='data'
                                       boxSize={{base: '150px', md: '100px'}}>
                            <Square size={{base: '150px', md: "100px"}}>
                                <Icon as={IoAdd} boxSize="10" color="black"/>
                            </Square>
                        </AssetDropzone>
                        : null
                }
            </Stack>
        </VStack>
    )
}