import {
    AspectRatio,
    Box,
    VStack,
    Image,
    Stack,
    Text,
    Spacer,
    Tooltip,
} from '@chakra-ui/react'
import {useNavigate} from "react-router";
import {GenerationsCount} from "@krfts/app-shared-components";
import {useEffect, useState} from "react";
import {createDownloadURL} from "@krfts/core-utils";
import {StatusBadge} from "../product-details/product-data/product-state/status-badge";

export const ProductCard = (props) => {

    const [productImageURL, setProductImageURL] = useState(null)

    const [modelStatus, setModelStatus] = useState("WAITING")
    const [loraStatus, setLoraStatus] = useState("WAITING")

    const navigate = useNavigate()
    const {product, maxW, fontSize, p, hideInfo} = props

    useEffect(() => {
        const getProductImageURL = async () => {
            setProductImageURL(await createDownloadURL(product.product_image.file))
        }
        getProductImageURL()
    }, [])

    useEffect(() => {
        if (product.trainings) { // new product data model
            const activeTraining = product.active_training
            setModelStatus(product.trainings[activeTraining].model_3D_generation.status)
            setLoraStatus(product.trainings[activeTraining].lora_training.status)
        } else { // legacy data model
            setModelStatus(product.model_3D_generation.status)
            setLoraStatus(product.lora_training.status)
        }
    }, [product])

    return (
        <Stack direction='column' spacing="4" cursor='pointer' maxW={maxW || '200px'} onClick={() => {
            navigate(product.id)
        }}>
            <Box bg='black' p={p || 6}>

                <Stack direction='column' spacing={4}>
                    <AspectRatio ratio={1}>
                        <Image
                            src={productImageURL}
                            alt={product.name}
                            draggable="false"
                        />
                    </AspectRatio>

                    <VStack align='left'>
                        <Tooltip label={product.name} placement='top'>
                            <Text
                                color='white'
                                fontSize={fontSize || "2xs"}
                                fontWeight="semibold"
                                letterSpacing="wider"
                                textTransform="uppercase"
                                isTruncated
                            >
                                {product.name}
                            </Text>
                        </Tooltip>

                        {hideInfo ? null :
                            <Stack direction={{base: 'column', md: 'row'}} w='100%' spacing={{base: 1, md: 2}}>
                                <GenerationsCount product={product}/>
                                <Spacer/>
                                <StatusBadge modelStatus={modelStatus}
                                             loraStatus={loraStatus} size='2xs'/>
                            </Stack>}

                    </VStack>
                </Stack>

            </Box>
        </Stack>
    )
}