import {Button, Stack} from "@chakra-ui/react";
import { IoCubeOutline, IoHomeOutline, IoLayersOutline   } from "react-icons/io5";

export const DashboardMenu = ({setSelectedContent}) => {

    return(
        <Stack align='start'>
            <Button
                _hover={{bg: "gray.600"}}
                leftIcon={<IoHomeOutline/>}
                variant='ghost' color='white'
                size='sm'
                onClick={() => {setSelectedContent("home")}}
            >
                Home
            </Button>
            <Button
                _hover={{bg: "gray.600"}}
                leftIcon={<IoCubeOutline/>}
                variant='ghost'
                color='white'
                size='sm'
                onClick={() => {setSelectedContent("products")}}
            >
                Latest Products
            </Button>
            <Button
                _hover={{bg: "gray.600"}}
                leftIcon={<IoLayersOutline/>}
                variant='ghost'
                color='white'
                size='sm'
                onClick={() => {setSelectedContent("generations")}}
            >
                Latest Generations
            </Button>
        </Stack>
    )

}