import {Box, Stack, Center} from "@chakra-ui/react";
import {TextBox} from "@krfts/core-text-box";
import {Logo} from "@krfts/app-logo"

export const ServersDownContent = () => {

    return (
        <>
            <Center color='white' w='100%'>
                <Stack direction='column' px={{base: 10, md: 40}} w='100%' align='center' spacing={10}>
                    <Box
                        bg="black"
                        p={1}
                    >
                        <Logo w={{base: '300px', md: '450px'}}/>
                    </Box>

                    <Box mt="10" w={{base: '95%', md: '60%'}} align='center' bg='black' pb={4}>
                        <Stack direction='column' spacing={6}>
                            <TextBox
                                text="Our servers are down performing some much needed self-reflection. We'll be back up shortly."
                            />

                        </Stack>
                    </Box>


                </Stack>

            </Center>
        </>);
};