import {useAuthStore} from "@krfts/app-state";
import {getUser} from "@krfts/app-auth-modal/data/auth-api";
import {QueryClient} from '@tanstack/react-query'

const queryClient = new QueryClient()

export const hasEnoughCreditsForAction = async (costOfAction) => {
    const currentUser = useAuthStore.getState().currentUser
    const data = await queryClient.fetchQuery({
        queryKey: ['user', currentUser.uid],
        queryFn: () => getUser(currentUser)
    })

    let totalCredits = 0

    if (data) {
        totalCredits = data.credits.purchased_credits + data.credits.plan_credits
    }

    return totalCredits >= costOfAction
}

