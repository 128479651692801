import {getApps, initializeApp} from 'firebase/app';
import {firebaseConfig} from "@krfts/app-common"
import {create} from 'zustand'
import {
    getAuth,
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    createUserWithEmailAndPassword,
    GoogleAuthProvider,
    signInWithPopup,
    signInWithRedirect,
    getRedirectResult,
    sendPasswordResetEmail
} from "firebase/auth";
import {getUser, userExists, createUser} from "@krfts/app-auth-modal/data/auth-api";
import {  isMobile } from 'react-device-detect';

if (getApps().length === 0) {
    initializeApp(firebaseConfig)
}

const mapErrorCodes = {
    "auth/email-already-in-use": "Email already in use",
    "auth/invalid-email": "Invalid email",
    "auth/user-disabled": "User account disabled",
    "auth/user-not-found": "User not found",
    "auth/invalid-credential": "There was an issue with your login. Please check your login details, try again or contact support if it continues.",
}

let auth = getAuth()
const provider = new GoogleAuthProvider();

export const useAuthStore = create((set) => ({
    currentUser: null,
    signInError: "",
    signUpError: "",
    signOutError: "",
    loading: true,
    showAuthModal: false,
    authModalMode: "signIn",

    betaAuthorized: false,
    setBetaAuthorized: (val) => {
        set({betaAuthorized: val})
    },

    setShowAuthModal: (val) => {
        set({showAuthModal: val})
    },
    setAuthModalMode: (val) => {
        set({authModalMode: val})
    },
    signIn: async (credentials) => {
        set({signInError: ""})

        const {email, password} = credentials
        try {
            await signInWithEmailAndPassword(auth, email, password)
            return true
        } catch (error) {
            console.log("signin error:", error.code)
            set({signInError: mapErrorCodes[error.code] || error.code})
            return false
        }
    },
    signInWithGoogle: async () => {
        try {
            let response
            if (isMobile) {
                response = await signInWithRedirect(auth, provider)
                await getRedirectResult(auth)
            } else {
                if (process.env.NODE_ENV === `development`) {
                    await signInWithPopup(auth, provider)
                } else {
                    await signInWithRedirect(auth, provider)
                }
            }

        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            const email = error.customData.email;
            const credential = GoogleAuthProvider.credentialFromError(error);
        }
    },
    signUp: async (credentials) => {
        const {email, password} = credentials
        try {
            const response = await createUserWithEmailAndPassword(auth, email, password)
            await createUser(response.user)

            // 'sign in' the user so the updated profile data gets refreshed
            await signOut(auth)
            await signInWithEmailAndPassword(auth, email, password)
        } catch (error) {
            console.log(error)
            set({signUpError: error.message})
        }
    },
    signOut: async () => {
        try {
            await signOut(auth)
        } catch (error) {
            set({signOutError: error.message})
        }
    },

    resetPassword: async (email) => {
        try {
            await sendPasswordResetEmail(auth, email)
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            return {errorCode, errorMessage}
        }
    }
}))

onAuthStateChanged(auth, async (currentUser) => {
    // console.log("AuthStateChanged: ", currentUser)

    if (currentUser && !await userExists(currentUser)) {
        await createUser(currentUser)
    }

    if (currentUser) {
        // const response = await getUser(currentUser)
        // currentUser.krfts = response.data

        // console.log("AuthStateChanged: ", currentUser)

        useAuthStore.setState({
            currentUser: currentUser,
            loading: false
        })
    } else {
        // console.log("AuthStateChanged: ", currentUser)
        useAuthStore.setState({
            currentUser: null,
            loading: false
        })
    }
})