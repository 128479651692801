import {Button, Drawer, DrawerBody, DrawerContent, Stack, Text, useDisclosure} from '@chakra-ui/react'
import {ToggleButton} from './toggle-button'
import {NavLink} from "react-router";

export const MobileDrawer = () => {
    const {isOpen, onToggle, onClose} = useDisclosure()
    return (
        <>
            <ToggleButton
                isOpen={isOpen}
                onClick={onToggle}
                aria-label="Open menu"
                display={{base: 'inline-flex', lg: 'none'}}
                zIndex={9999}
            />
            <Drawer placement="top" isOpen={isOpen} onClose={onClose}>
                <DrawerContent bg="transparent">
                    <DrawerBody mt="55px" p="4" bg="#101214">
                        <Stack spacing="1">
                            {/*<NavLink to="/dashboard" end>*/}
                            {/*    <Button variant='tertiary' justifyContent="start" size='lg' color='white'>DASHBOARD</Button>*/}
                            {/*</NavLink>*/}
                            <NavLink to="/products" end>
                                <Button variant='tertiary' justifyContent="start" size='lg' color='white'>MY PRODUCTS</Button>
                            </NavLink>
                            <NavLink to="/studio" end>
                                <Button variant='tertiary' justifyContent="start" size='lg'>
                                    <Text bgGradient="linear(to-r, yellow.300, pink.300)" bgClip='text'>STUDIO
                                    </Text>
                                </Button>
                            </NavLink>
                        </Stack>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}