import {Box, Flex, Stack, VStack, HStack, Heading, Text, Button, Divider} from '@chakra-ui/react'
import {useState} from "react";
import {PlansModal} from "./plans-modal";
import {CreditsModal} from "./credits-modal";
import {useNavigate, redirect} from "react-router";
import {subDescription, getCreditsCopy} from "../utils/dashboard-utils";
import {priceIds} from "@krfts/app-common/stripe-config";

// should be refactored to its own route and endpoint
import {
    getStripeBillingSessionUrl,
    getStripeCustomerSessionUrl,
    checkStripeCustomerSubscriptionStatus, createStripeSubscriptionCheckoutSession, createStripeCreditsCheckoutSession,
} from "@krfts/app-auth-modal/data/auth-api";

export const BillingDashboard = ({user}) => {

    const [showPlansModal, setShowPlansModal] = useState(false);
    const [showCreditsModal, setShowCreditsModal] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);

    const navigate = useNavigate();

    return (
        <>
            <VStack spacing={14} w='100%' align='start'>
                <Heading size='md'>Your Plan Details</Heading>
                <Stack direction={{base: 'column', md: 'row'}} spacing={{base: 8, md: 32}} align='top'
                       justifyContent='space-between' w='100%'>
                    <Heading minW='50px' size='sm'>Subscription</Heading>

                    <VStack align="start" spacing={{base: 1, md: 2}}>
                        <Heading size='sm'>{subDescription(user.subscription.current_plan).title}</Heading>
                        <Text>{subDescription(user.subscription.current_plan).description}</Text>
                    </VStack>

                    <HStack>
                        <Button
                            isDisabled={true} // TODO: Remove for Live!
                            bg='yellow.300'
                            _hover={{bg: 'yellow.100'}}
                            onClick={async () => {
                                const result = await getStripeBillingSessionUrl(user)
                                // console.log(result.data)
                                window.location.href = result.data.url
                            }}
                        >
                            Manage billing
                        </Button>


                        <Button
                            isDisabled={true} // TODO: Remove for Live!
                            color='white'
                            bgGradient="linear(to-r, pink.600, purple.600)"
                            _hover={{bgGradient: "linear(to-r, pink.400, purple.400)"}}
                            onClick={async () => {
                                const result = await checkStripeCustomerSubscriptionStatus(user)
                                if (result.data.data[0]) {
                                    if (result.data.data[0].status === 'active') {
                                        console.log("current plan:", result.data.data[0])
                                        setSelectedPlan(result.data.data[0].plan.id)
                                    }
                                }

                                setShowPlansModal(true)
                            }}>Select a Plan
                        </Button>

                    </HStack>

                </Stack>

                <Divider/>
                <Stack direction={{base: 'column', md: 'row'}} spacing={{base: 8, md: 32}} align='top'
                       justifyContent='space-between' w='100%'>
                    <Heading minW='150px' size='sm'>Credits</Heading>/

                    <VStack align="start" spacing={8}>
                        <VStack align='start' spacing={1}>
                            <Heading size='sm'>{user.credits.plan_credits} monthly credits</Heading>
                            <Text>{getCreditsCopy(user.subscription)}</Text>
                        </VStack>

                        <VStack align='start' spacing={1}>
                            <Heading size='sm'>{user.credits.purchased_credits} purchased credits</Heading>
                            <Text>Purchased credits won't expire</Text>
                        </VStack>
                    </VStack>

                    <HStack>
                        {/*<Button bg='yellow.300' _hover={{bg: 'yellow.100'}}>View FAQs</Button>*/}
                        <Button
                            isDisabled={true} // TODO: Remove for Live!
                            color='white'
                            bgGradient="linear(to-r, pink.600, purple.600)"
                            _hover={{bgGradient: "linear(to-r, pink.400, purple.400)"}}
                            onClick={async () => {
                                setShowCreditsModal(true)
                            }}
                        >
                            Get Credits
                        </Button>
                    </HStack>
                </Stack>

                <Divider/>

            </VStack>
            <PlansModal isOpen={showPlansModal} setShouldShowModal={setShowPlansModal} user={user}
                        selectedPlan={selectedPlan}/>
            <CreditsModal isOpen={showCreditsModal} setShouldShowModal={setShowCreditsModal} user={user} selectedPlan={selectedPlan}/>
        </>
    )
}