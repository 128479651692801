import {
    Box,
    Image,
    Square,
    VStack,
    Text,
    chakra,
    Center,
} from '@chakra-ui/react'

import {AssetDropzone} from "@krfts/core-asset-dropzone";
import {ProductImage} from "../product-image";
import {RemoveButton} from "@krfts/app-shared-components";
import {useEffect, useState} from "react";
import {LoadingIndicator} from "@krfts/core-ui-library";

export const ModelImage = ({image, icon, onImageRemoved, onFileDrop, title, allowRemove}) => {
    const [isDropping, setIsDropping] = useState(false)

    const handleDrop = (files, assetType) => {
        setIsDropping(true)
        onFileDrop(files, assetType)
    }

    useEffect(() => {
        if (isDropping && image) setIsDropping(false)
    }, [image]);

    if (isDropping) {
        return (
            <Box boxSize={{base: '150px', md: '100px'}}>
                <Center h='100%'>
                    <LoadingIndicator color='white' size='sm' thickness='2px'/>
                </Center>
            </Box>
        )
    }

    return (
        image ?
            <Box position='relative'>
                <ProductImage image={image} onImageRemoved={onImageRemoved}/>

                {allowRemove ? <RemoveButton position='absolute' top={-0.5} right={-0.5} onClick={() => {
                    onImageRemoved(null)
                }}/> : null}
            </Box>
            :
            <AssetDropzone onFileDrop={(acceptedFiles, assetType) => handleDrop(acceptedFiles, assetType)}
                           assetType={title} boxSize={{base: '150px', md: '100px'}} p={0}>
                <VStack align='center' boxSize={{base: '150px', md: '100px'}} py={2}>
                    <Square size="10">
                        <Image src={icon}/>
                    </Square>
                    <Text align='center' color='black' fontSize='2xs'>Drag / Click to add <chakra.span
                        fontWeight={900}>{title}</chakra.span></Text>
                </VStack>
            </AssetDropzone>
    )
}