import axios from "axios";
import {
    API_BASE_URL,
    PRODUCT_ENDPOINT,
    PRODUCT_ALL_ENDPOINT,
    GENERATIONS_FOR_PRODUCT_ENDPOINT
} from "@krfts/app-common";
import {useAuthStore} from "@krfts/app-state";

export const createProduct = async (productName, productImage, sourceImages, modelImages, productDimensions) => {
    const idToken = await useAuthStore.getState().currentUser.getIdToken(true)

    const formData = new FormData();
    formData.append("product-name", productName);
    formData.append("product-dimensions", JSON.stringify(productDimensions));

    const productImageFilename = "product-image." + productImage.file.name.slice((Math.max(0, productImage.file.name.lastIndexOf(".")) || Infinity) + 1)
    // formData.append("product-image", productImage.file, productImage.file.name)
    formData.append("product-image", productImage.file, productImageFilename)

    sourceImages.map((image, i) => {
        const key = `source-image${i}`
        const sourceImageFilename = key + "." + image.file.name.slice((Math.max(0, image.file.name.lastIndexOf(".")) || Infinity) + 1)
        formData.append(key, image.file, sourceImageFilename)
        // formData.append(key, image.file, image.file.name)
    })

    modelImages.map((image, i) => {
        const key = `model-image${i}`
        const modelImageFilename = key + "." + image.file.name.slice((Math.max(0, image.file.name.lastIndexOf(".")) || Infinity) + 1)
        formData.append(key, image.file, modelImageFilename)
        // formData.append(key, image.file, image.file.name)
    })

    try {
        const response = await axios.post(API_BASE_URL + PRODUCT_ENDPOINT, formData, {
            headers: {
                'Authorization': `Bearer ${idToken}`,
                'Content-Type': 'multipart/form-data',
            }
        })
        return response.data;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
}

export const startNewTraining = async (product, sourceImages, modelImages) => {
    const idToken = await useAuthStore.getState().currentUser.getIdToken(true)

    const formData = new FormData();

    sourceImages.map((image, i) => {
        const key = `source-image${i}`
        const sourceImageFilename = key + "." + image.file.name.slice((Math.max(0, image.file.name.lastIndexOf(".")) || Infinity) + 1)
        formData.append(key, image.file, sourceImageFilename)
        // formData.append(key, image.file, image.file.name)
    })

    modelImages.map((image, i) => {
        const key = `model-image${i}`
        const modelImageFilename = key + "." + image.file.name.slice((Math.max(0, image.file.name.lastIndexOf(".")) || Infinity) + 1)
        formData.append(key, image.file, modelImageFilename)
        // formData.append(key, image.file, image.file.name)
    })

    formData.append("product", JSON.stringify(product))

    try {
        const response = await axios.put(API_BASE_URL + PRODUCT_ENDPOINT, formData, {
            headers: {
                'Authorization': `Bearer ${idToken}`,
                'Content-Type': 'multipart/form-data',
            }
        })
        return response.data;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
}

export const updateProduct = async (
    productId,
    productName,
    productImage,
    productDimensions,
    defaultPrompt,
    activeTrainingId,
    custom3DModel,
    custom3DModelRotOffset,
    attentionTo3d,
    guidanceImage,
    attentionToGuidance) => {
    const currentUser = await useAuthStore.getState().currentUser
    const idToken = await currentUser.getIdToken(true)

    const formData = new FormData();
    formData.append("product-name", productName);
    formData.append("product-dimensions", JSON.stringify(productDimensions));
    const productImageFilename = "product-image." + productImage.file.name.slice((Math.max(0, productImage.file.name.lastIndexOf(".")) || Infinity) + 1)
    formData.append("product-image", productImage.file, productImageFilename)
    formData.append("default-prompt", defaultPrompt)
    formData.append("active-training-id", activeTrainingId)
    formData.append("product-id", productId)
    formData.append("attention-to-3d", attentionTo3d)
    formData.append("user-id", currentUser.uid)
    formData.append("attention-to-guidance", attentionToGuidance)
    if (custom3DModel) {
        const custom3DModelFilename = "product-3d-model." + custom3DModel.file.name.slice((Math.max(0, productImage.file.name.lastIndexOf(".")) || Infinity) + 1)
        formData.append("custom-3d-model", custom3DModel.file, custom3DModelFilename)
        formData.append("custom-3d-model-rot-offset", JSON.stringify(custom3DModelRotOffset))
    } else {
        formData.append("custom-3d-model", null)
    }
    if (guidanceImage) {
        const guidanceImageFilename = "guidance-image." + guidanceImage.file.name.slice((Math.max(0, guidanceImage.file.name.lastIndexOf(".")) || Infinity) + 1)
        formData.append("guidance-image", guidanceImage.file, guidanceImageFilename)
    } else {
        formData.append("guidance-image", null)
    }

    try {
        const response = await axios.patch(API_BASE_URL + PRODUCT_ENDPOINT, formData, {
            headers: {
                'Authorization': `Bearer ${idToken}`,
                'Content-Type': 'multipart/form-data',
            }
        })
        return response.data;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
}

export const getProducts = async () => {
    const idToken = await useAuthStore.getState().currentUser.getIdToken(true)

    try {
        const response = await axios.get(API_BASE_URL + PRODUCT_ALL_ENDPOINT, {
            headers: {
                'Authorization': `Bearer ${idToken}`
            }
        })
        return response.data;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
}

export const getProduct = async (productId) => {
    const idToken = await useAuthStore.getState().currentUser.getIdToken(true)
    try {
        const response = await axios.get(API_BASE_URL + PRODUCT_ENDPOINT + "/" + productId, {
            headers: {
                'Authorization': `Bearer ${idToken}`
            }
        })
        return response.data;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
}

export const getGenerationsForProduct = async (productId) => {
    const idToken = await useAuthStore.getState().currentUser.getIdToken(true)
    try {
        const response = await axios.get(API_BASE_URL + GENERATIONS_FOR_PRODUCT_ENDPOINT + "/" + productId, {
            headers: {
                'Authorization': `Bearer ${idToken}`
            }
        })
        return response.data;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
}

export const regenerate3DModel = async (productId, trainingId) => {
    const uid = await useAuthStore.getState().currentUser.uid
    const idToken = await useAuthStore.getState().currentUser.getIdToken(true)

    const data = {
        productId: productId,
        trainingId: trainingId,
        uid: uid,
    }

    try {
        const response = await axios.post(API_BASE_URL + PRODUCT_ENDPOINT + "/retrain", data, {
            headers: {
                'Authorization': `Bearer ${idToken}`,
                'Content-Type': 'application/json',
            }
        })
        return response.data;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
}