import axios from "axios";
import {
    API_BASE_URL,
    IMAGE_GENERATION_ENDPOINT,
    VIDEO_GENERATION_ENDPOINT,
    VIDEO_CAMERA_MOTIONS_ENDPOINT,
    GENERATION_ENDPOINT,
    SCENE_DESCRIPTION_ENDPOINT,
    IMAGE_INPAINT_GENERATION_ENDPOINT,
    ADMIN_LATEST_GENERATIONS_ENDPOINT,
} from "@krfts/app-common";
import {useAuthStore} from "@krfts/app-state";

export const queueImageGeneration = async (generationRequestData) => {

    const uid = useAuthStore.getState().currentUser.uid
    const idToken = await useAuthStore.getState().currentUser.getIdToken(true)

    const {
        productId,
        productPrompt,
        bgPrompt,
        bgGuidanceImage,
        bgGuidanceAttentionToDetail,
        outlineImage,
        depthImage,
        maskImage,
        objectTransform,
        cameraTransform,
        imageFormat,
        loraName,
        loraKeyword,
        seed,
        isIteration,
        parentGeneration,
        attentionTo3D,
        parentPrompt,
        parentProductPrompt,
        parentBgPrompt,
        productDimensions,
    } = generationRequestData

    const data = {
        userId: uid,
        productId: productId,
        bgPrompt: bgPrompt,
        productPrompt: productPrompt,
        imageFormat: imageFormat.toLowerCase() === "square" ? "1024x1024 (1.0)" : imageFormat.toLowerCase() === "portrait" ? "768x1280 (0.6)" : "1280x768 (1.67)",
        depthImage: depthImage,
        outlineImage: outlineImage,
        maskImage: maskImage,
        objectTransform: objectTransform,
        cameraTransform: cameraTransform,
        loraKeyword: loraKeyword,
        loraName: loraName,
        seed: seed,
        isIteration: isIteration,
        attentionTo3d: attentionTo3D,
        productDimensions: productDimensions,
    }

    // console.log("Sending gen data: ", data)

    if (bgGuidanceImage) {
        data["bgGuidanceImage"] = bgGuidanceImage
        data["bgGuidanceAttentionToDetail"] = bgGuidanceAttentionToDetail
    }

    if (parentGeneration) {
        data["parentGeneration"] = parentGeneration
        data["parentPrompt"] = parentPrompt
        data["parentProductPrompt"] = parentProductPrompt
        data["parentBgPrompt"] = parentBgPrompt
    }

    // console.log("Sending gen data: ", data)

    try {
        const response = await axios.post(API_BASE_URL + IMAGE_GENERATION_ENDPOINT, data, {
            headers: {
                'Authorization': `Bearer ${idToken}`,
            }
        })
        return response;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
}

export const queueInpaintingImageGeneration = async (generationRequestData) => {

    const uid = useAuthStore.getState().currentUser.uid
    const idToken = await useAuthStore.getState().currentUser.getIdToken(true)

    const {productId,
        productPrompt,
        bgPrompt,
        inpaintingMaskImage,
        inpaintingTargetImage,
        inpaintingPrompt,
        bgGuidanceImage,
        bgGuidanceAttentionToDetail,
        outlineImage,
        depthImage,
        maskImage,
        objectTransform,
        cameraTransform,
        imageFormat,
        loraName,
        loraKeyword,
        seed,
        isIteration,
        parentGeneration,
    } = generationRequestData

    const data = {
        userId: uid,
        productId: productId,
        inpaintingPrompt: inpaintingPrompt,
        inpaintingMaskImage: inpaintingMaskImage,
        inpaintingTargetImage: inpaintingTargetImage,
        bgPrompt: bgPrompt,
        productPrompt: productPrompt,
        imageFormat: imageFormat,
        depthImage: depthImage,
        outlineImage: outlineImage,
        maskImage: maskImage,
        objectTransform: objectTransform,
        cameraTransform: cameraTransform,
        loraKeyword: loraKeyword,
        loraName: loraName,
        seed: seed,
        isIteration: isIteration,
    }

    if (bgGuidanceImage) {
        data["bgGuidanceImage"] = bgGuidanceImage
        data["bgGuidanceAttentionToDetail"] = bgGuidanceAttentionToDetail
    }

    if (parentGeneration) {
        data["parentGeneration"] = parentGeneration
    }

    // console.log("Sending gen data: ", data)

    try {
        const response = await axios.post(API_BASE_URL + IMAGE_INPAINT_GENERATION_ENDPOINT, data, {
            headers: {
                'Authorization': `Bearer ${idToken}`,
            }
        })
        return response;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
}

export const getCameraMotions = async () => {
    const idToken = await useAuthStore.getState().currentUser.getIdToken(true)

    try {
        const response = await axios.get(API_BASE_URL + VIDEO_CAMERA_MOTIONS_ENDPOINT, {
            headers: {
                'Authorization': `Bearer ${idToken}`,
            }
        })
        return response.data;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
}

export const queueVideoGeneration = async (generation, prompt, imageURL, cameraMotion, frame) => {
    const user = await useAuthStore.getState().currentUser
    const idToken = await user.getIdToken(true)

    const data = {
        productId: generation.product_id,
        generationId: generation.id,
        prompt: prompt,
        imageURL: imageURL,
        imageFile: generation.image.file,
        userId: user.uid,
        cameraMotion: cameraMotion,
        frame: frame,
    }

    try {
        const response = await axios.post(API_BASE_URL + VIDEO_GENERATION_ENDPOINT, data, {
            headers: {
                'Authorization': `Bearer ${idToken}`,
            }
        })
        return response;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
}

export const getGeneration = async (generationId) => {
    const idToken = await useAuthStore.getState().currentUser.getIdToken(true)

    try {
        const response = await axios.get(API_BASE_URL + GENERATION_ENDPOINT + "/" + generationId, {
            headers: {
                'Authorization': `Bearer ${idToken}`,
            }
        })
        return response.data;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
}

export const get3DSceneDescription = async (data) => {
    const idToken = await useAuthStore.getState().currentUser.getIdToken(true)

    console.log("Generating scene description with: ", data)

    try {
        const response = await axios.post(API_BASE_URL + SCENE_DESCRIPTION_ENDPOINT, data, {
            headers: {
                'Authorization': `Bearer ${idToken}`,
            }
        })
        return response.data;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
}

