import {
    Box,
    FormControl,
    FormLabel,
    Icon,
    Input,
    Square,
    Image,
    Stack,
    chakra,
    HStack, Center,
} from '@chakra-ui/react'
import {AssetDropzone} from "@krfts/core-asset-dropzone";
import {IoAdd} from "react-icons/io5";
import {RemoveButton} from "@krfts/app-shared-components";
import dimensionsIcon from "../../assets/dimensions.png";
import {useEffect, useState} from "react";
import {LoadingIndicator} from "@krfts/core-ui-library";

export const Metadata = (props) => {

    const {name, onNameChange, productDimensions, onProductDimensionsChange} = props

    const checkIfNumber = (value) => {
        const re = /^[0-9\b]+$/;
        if (value === '' || re.test(value)) {
            return true
        } else {
            return false
        }
    }

    const [isDropping, setIsDropping] = useState(false)

    const handleDrop = (files, assetType) => {
        setIsDropping(true)
        props.onFileDrop(files, assetType)
    }

    useEffect(() => {
        if (isDropping && props.productImage) setIsDropping(false)
    }, [props.productImage]);

    return (
        <Box flex="1" w='100%' px={8}>
            <FormControl id="product-name">
                <Stack direction={{base: 'column', md: 'row'}} spacing={10} align='top'>
                    <Box>
                        <FormLabel fontSize='sm'>Product photo</FormLabel>

                        {
                            props.productImage ?
                                <Box>
                                    <Box position='relative' boxSize='120px'>
                                        <Image src={props.productImage.src}/>
                                        <RemoveButton position='absolute' top={-0.5} right={-0.5} onClick={() => {
                                            props.onProductImageRemoved(false)
                                        }}/>
                                    </Box>
                                </Box>
                                :
                                isDropping ?
                                    <Box boxSize='120px'>
                                        <Center h='100%'>
                                            <LoadingIndicator color='white' size='sm' thickness='2px'/>
                                        </Center>
                                    </Box>
                                    :
                                    <AssetDropzone onFileDrop={(files, assetType) => handleDrop(files, assetType)}
                                                   assetType='metadata' boxSize='120px'>
                                        <Square size="120px" bg="transparent">
                                            <Icon as={IoAdd} boxSize="10" color="black"/>
                                        </Square>
                                    </AssetDropzone>}

                    </Box>


                    <Stack direction={{base: 'column', md: 'column'}} spacing={9} align='top' w='100%'
                           justify='space-between'>
                        <Box w={{base: '100%', md: '50%'}}>
                            <FormControl isRequired>
                                <FormLabel fontSize='sm'>Product name</FormLabel>
                                <Input
                                    size='sm'
                                    rounded='0'
                                    placeholder='Name of the product'
                                    bg='gray.100'
                                    color='black'
                                    fontSize='sm'
                                    value={name}
                                    onChange={(e) => onNameChange(e.target.value)}
                                />
                            </FormControl>
                        </Box>

                        <Box w={{base: '100%', md: '65%'}}>
                            <FormControl>
                                <FormLabel fontSize='xs'>Product dimensions <chakra.i>(optional)</chakra.i></FormLabel>
                                <HStack>
                                    <HStack mt='-10px'>
                                        <Input
                                            size='xs'
                                            rounded='0'
                                            placeholder='width (cm)'
                                            bg='gray.100'
                                            color='black'
                                            fontSize='xs'
                                            value={productDimensions && productDimensions.width ? productDimensions.width : ''}
                                            onChange={(e) => {
                                                if (checkIfNumber(e.target.value)) {
                                                    onProductDimensionsChange(
                                                        {
                                                            width: e.target.value,
                                                            height: productDimensions.height,
                                                            depth: productDimensions.depth
                                                        }
                                                    )
                                                }
                                            }
                                            }
                                        />
                                        <Input
                                            size='xs'
                                            rounded='0'
                                            placeholder='depth (cm)'
                                            bg='gray.100'
                                            color='black'
                                            fontSize='xs'
                                            value={productDimensions && productDimensions.depth > 0 ? productDimensions.depth : ''}
                                            onChange={(e) => {
                                                if (checkIfNumber(e.target.value)) {
                                                    onProductDimensionsChange(
                                                        {
                                                            width: productDimensions.width,
                                                            height: productDimensions.height,
                                                            depth: e.target.value
                                                        }
                                                    )
                                                }
                                            }
                                            }
                                        />
                                        <Input
                                            size='xs'
                                            rounded='0'
                                            placeholder='height (cm)'
                                            bg='gray.100'
                                            color='black'
                                            fontSize='xs'
                                            value={productDimensions && productDimensions.height ? productDimensions.height : ''}
                                            onChange={(e) => {
                                                if (checkIfNumber(e.target.value)) {
                                                    onProductDimensionsChange(
                                                        {
                                                            width: productDimensions.width,
                                                            height: e.target.value,
                                                            depth: productDimensions.depth
                                                        }
                                                    )
                                                }
                                            }
                                            }
                                        />
                                        <Image src={dimensionsIcon} boxSize='75px' mt='-27px'/>
                                    </HStack>
                                </HStack>
                            </FormControl>
                        </Box>
                    </Stack>

                </Stack>
            </FormControl>
        </Box>
    )
}