import {
    Menu,
    MenuList,
} from '@chakra-ui/react'
import {ProductSwitcherButton} from './product-switcher-button'
import {ProductSwitcherEntry} from './product-switcher-entry'
import {useStudioStore} from "../../../state";
import {useQuery} from "@tanstack/react-query";
import {getProducts} from "@krfts/app-data";
import {useState} from "react";


export const ProductSwitcher = () => {
    const selectedProduct = useStudioStore((state) => state.selectedProduct);
    const {data} = useQuery({queryKey: ['products'], queryFn: getProducts})

    return (
        <Menu>
            <ProductSwitcherButton selectedProduct={selectedProduct}/>
            <MenuList shadow="lg" py="4" color='gray.600' px={{base:1, md:3}} w={{base:'90%', md:"100%"}} overflowY='auto' maxH='80vh'>
                {
                    data.map((product, i) => {
                        if (product.trainings) {
                            const activeTraining = product.active_training
                            if (product.trainings[activeTraining].lora_training.status === 'DONE' && product.trainings[activeTraining].model_3D_generation.status === 'DONE')
                                return <ProductSwitcherEntry key={i} product={product}/>
                        } else {
                            if (product.lora_training.status === 'DONE' && product.model_3D_generation.status === 'DONE')
                                return <ProductSwitcherEntry key={i} product={product}/>
                        }
                        }
                    )
                }
            </MenuList>
        </Menu>
    )
}