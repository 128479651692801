import {useEffect, useState} from "react";
import {Divider, Stack, Text, VStack, Button, useToast, Image, Box, Tooltip} from '@chakra-ui/react'
import {ProductSwitcher} from "./product-switcher";
import {ImageFormat} from "./image-format";
import {ProductPositioner} from "./product-positioner";
import {Prompt} from "@krfts/core-ui-library";
import {GuidanceImage} from "./guidance-image";
import {useStudioStore} from "../../state";
import {useAuthStore} from "@krfts/app-state";
import {queueImageGeneration, queueInpaintingImageGeneration} from "../../data/studio-api";
import {createDownloadURL} from "@krfts/core-utils";
import Compressor from 'compressorjs';
import {costs} from "@krfts/app-common";
import {useQuery} from "@tanstack/react-query";
import {getUser} from "@krfts/app-auth-modal/data/auth-api";

export const GenerationControls = ({mobileDrawerCallback}) => {

    const [isSubmittingToQueue, setIsSubmittingToQueue] = useState(false)

    const currentUser = useAuthStore((state) => state.currentUser)

    const shouldGenerateImage = useStudioStore((state) => state.shouldGenerateImage)
    const setShouldGenerateImage = useStudioStore((state) => state.setShouldGenerateImage)

    const setShouldCaptureControlImages = useStudioStore((state) => state.setShouldCaptureControlImages)
    const controlImagesCaptured = useStudioStore((state) => state.controlImagesCaptured)
    const setControlImagesCaptured = useStudioStore((state) => state.setControlImagesCaptured)

    const imageFormat = useStudioStore((state) => state.imageFormat)

    const outlineImage = useStudioStore((state) => state.outlineImage)
    const depthImage = useStudioStore((state) => state.depthImage)
    const maskImage = useStudioStore((state) => state.maskImage)

    const objectTransform = useStudioStore((state) => state.objectTransform)
    const cameraTransform = useStudioStore((state) => state.cameraTransform)

    const productPrompt = useStudioStore((state) => state.productPrompt)
    const setProductPrompt = useStudioStore((state) => state.setProductPrompt)

    const inpaintingPrompt = useStudioStore((state) => state.inpaintingPrompt)
    const setInpaintingPrompt = useStudioStore((state) => state.setInpaintingPrompt)

    const bgPrompt = useStudioStore((state) => state.bgPrompt)
    const setBgPrompt = useStudioStore((state) => state.setBgPrompt)

    const bgGuidanceImage = useStudioStore((state) => state.bgGuidanceImage)
    const setBgGuidanceImage = useStudioStore((state) => state.setBgGuidanceImage)
    const bgGuidanceAttentionToDetail = useStudioStore((state) => state.bgGuidanceAttentionToDetail)
    const setBgGuidanceAttentionToDetail = useStudioStore((state) => state.setBgGuidanceAttentionToDetail)

    const selectedProduct = useStudioStore((state) => state.selectedProduct)

    const lockedGeneration = useStudioStore((state) => state.lockedGeneration)
    const setLockedGeneration = useStudioStore((state) => state.setLockedGeneration)

    const inpaintingImage = useStudioStore((state) => state.inpaintingImage)
    const setInpaintingImage = useStudioStore((state) => state.setInpaintingImage)
    const inpaintingMaskCaptured = useStudioStore((state) => state.inpaintingMaskCaptured)
    const setShouldCaptureInpaintingMask = useStudioStore((state) => state.setShouldCaptureInpaintingMask)
    const inpaintingMaskImage = useStudioStore((state) => state.inpaintingMaskImage)
    const setInpaintingMaskCaptured = useStudioStore((state) => state.setInpaintingMaskCaptured)
    const setInpaintingMaskImage = useStudioStore((state) => state.setInpaintingMaskImage)

    const addToCurrentSessionGenerations = useStudioStore((state) => state.addToCurrentSessionGenerations)

    const setGenerationCurrentlyRunning = useStudioStore((state) => state.setGenerationCurrentlyRunning);

    const [hasEnoughCredits, setHasEnoughCredits] = useState(false)

    const {isPending, isError, data, error} = useQuery(
        {
            queryKey: ['user', currentUser.uid],
            queryFn: () => getUser(currentUser)
        })

    const toast = useToast()

    // Regular generation
    useEffect(() => {
        if (controlImagesCaptured) {
            // console.log("starting regular image generation")
            const queueRequest = async () => {
                // console.log("starting image generation")
                setIsSubmittingToQueue(true)

                let loraTriggerWord
                let loraName = "flux/"
                if (selectedProduct.trainings) {
                    const activeTraining = selectedProduct.active_training
                    loraTriggerWord = selectedProduct.trainings[activeTraining].lora_training.trigger_word
                    loraName = loraName + selectedProduct.trainings[activeTraining].lora_training.file.split('/').pop()
                } else {
                    loraTriggerWord = selectedProduct.lora.trigger_word
                    loraName = loraName + selectedProduct.lora.file.split('/').pop()
                }

                const imageGenerationData = {
                    productId: selectedProduct.id,
                    productPrompt: productPrompt,
                    bgPrompt: bgPrompt,
                    bgGuidanceImage: bgGuidanceImage ? bgGuidanceImage : null,
                    bgGuidanceAttentionToDetail: bgGuidanceImage ? bgGuidanceAttentionToDetail / 100 * 1 : null,
                    outlineImage: outlineImage,
                    depthImage: depthImage,
                    maskImage: maskImage,
                    objectTransform: objectTransform,
                    cameraTransform: cameraTransform,
                    imageFormat: imageFormat,
                    loraName: loraName,
                    loraKeyword: loraTriggerWord + ' ' + selectedProduct.object_classification,
                    seed: lockedGeneration ? lockedGeneration.image_generation_data.seed : Math.floor(Math.random() * 1000000000),
                    isIteration: !!lockedGeneration,
                    parentGeneration: lockedGeneration ? lockedGeneration.id : null,
                    parentPrompt: lockedGeneration ? lockedGeneration.image_generation_data.prompt : null,
                    parentProductPrompt: lockedGeneration ? lockedGeneration.image_generation_data.product_prompt : null,
                    parentBgPrompt: lockedGeneration ? lockedGeneration.image_generation_data.bg_prompt : null,
                    attentionTo3D: selectedProduct.attention_to_3D ? selectedProduct.attention_to_3D : 0.55,
                    productDimensions: selectedProduct.product_dimensions || {width: 0, height: 0, depth: 0},
                }

                const queueResponse = await queueImageGeneration(imageGenerationData)

                if (!queueResponse) {
                    toast({
                        position: 'top',
                        title: "Error generating image",
                        description: "Please try again later.",
                        status: "error",
                        isClosable: true,
                    })
                } else {
                    addToCurrentSessionGenerations(queueResponse.data.generationId)
                    setGenerationCurrentlyRunning(queueResponse.data.generationId)
                }

                setIsSubmittingToQueue(false)
                setControlImagesCaptured(false);
                setShouldGenerateImage(false);
                if (mobileDrawerCallback) mobileDrawerCallback()
            }

            if (shouldGenerateImage) {
                queueRequest()
            }
        }
    }, [controlImagesCaptured]);


    // Inpainting generation
    useEffect(() => {
        if (inpaintingMaskCaptured) {
            // console.log("starting inpainting image generation")

            const queueRequest = async () => {
                setIsSubmittingToQueue(true)

                let loraTriggerWord
                let loraName = "flux/"
                if (selectedProduct.trainings) {
                    const activeTraining = selectedProduct.active_training
                    loraTriggerWord = selectedProduct.trainings[activeTraining].lora_training.trigger_word
                    loraName = loraName + selectedProduct.trainings[activeTraining].lora_training.file.split('/').pop()
                } else {
                    loraTriggerWord = selectedProduct.lora.trigger_word
                    loraName = loraName + selectedProduct.lora.file.split('/').pop()
                }

                const imageGenerationData = {
                    productId: selectedProduct.id,
                    inpaintingPrompt: inpaintingPrompt,
                    inpaintingMaskImage: inpaintingMaskImage,
                    inpaintingTargetImage: lockedGeneration.image,
                    productPrompt: lockedGeneration.image_generation_data.product_prompt + ', ' + loraTriggerWord + ' ' + selectedProduct.object_classification,
                    bgPrompt: lockedGeneration.image_generation_data.bg_prompt,
                    bgGuidanceImage: lockedGeneration.image_generation_data.bg_guidance_image ? lockedGeneration.image_generation_data.bg_guidance_image : null,
                    bgGuidanceAttentionToDetail: lockedGeneration.image_generation_data.bg_guidance_image ? lockedGeneration.image_generation_data.bg_guidance_image_attention_to_detail : 0,
                    outlineImage: lockedGeneration.image_generation_data.outline_image,
                    depthImage: lockedGeneration.image_generation_data.depth_image,
                    maskImage: lockedGeneration.image_generation_data.mask_image,
                    objectTransform: lockedGeneration.image_generation_data.object_transform,
                    cameraTransform: lockedGeneration.image_generation_data.camera_transform,
                    imageFormat: lockedGeneration.format,
                    loraName: loraName,
                    loraKeyword: loraTriggerWord + ' ' + selectedProduct.object_classification,
                    seed: lockedGeneration.image_generation_data.seed,
                    isIteration: true,
                    parentGeneration: lockedGeneration.id,
                }

                const queueResponse = await queueInpaintingImageGeneration(imageGenerationData)
                // console.log("Queue response:", queueResponse)
                addToCurrentSessionGenerations(queueResponse.data.generationId)

                // console.log("setting currently running gen: ", queueResponse.data.generationId)
                setGenerationCurrentlyRunning(queueResponse.data.generationId)

                // console.log("Ending image generation")
                setIsSubmittingToQueue(false)

                setInpaintingMaskCaptured(false);
                setShouldGenerateImage(false);

                setInpaintingMaskImage(null)
                setInpaintingImage(false)


                if (mobileDrawerCallback) mobileDrawerCallback()
            }

            if (shouldGenerateImage) {
                queueRequest()
            }

        }
    }, [inpaintingMaskCaptured, inpaintingMaskImage]);


    useEffect(() => {
        if (lockedGeneration) {
            setProductPrompt(lockedGeneration.image_generation_data.product_prompt)
            setBgPrompt(lockedGeneration.image_generation_data.bg_prompt)
            if (lockedGeneration.image_generation_data.bg_guidance_image) {
                createDownloadURL(lockedGeneration.image_generation_data.bg_guidance_image.file).then(url => setBgGuidanceImage(url))
                setBgGuidanceAttentionToDetail(Math.floor(lockedGeneration.image_generation_data.bg_guidance_image_attention_to_detail * 100 / 0.2))
            }
        } else {
            setProductPrompt("")
            setBgPrompt("")
            setBgGuidanceImage(null)
        }
    }, [lockedGeneration]);

    const onBgGuidanceImageAdded = (files) => {
        const file = files[0];
        const reader = new FileReader();

        new Compressor(file, {
            quality: 0.8,
            width: 1024,
            convertTypes: ['image/png', 'image/webp', 'image/heic'],
            convertSize: 500000,

            success(compressedFile) {
                reader.addEventListener("load", function () {
                    setBgGuidanceImage(reader.result);
                }, false);

                if (file) reader.readAsDataURL(compressedFile);
                setBgGuidanceAttentionToDetail(0)
            }
        })
    }

    useEffect(() => {
        if (data) {
            const totalCredits = data.credits.purchased_credits + data.credits.plan_credits
            setHasEnoughCredits(totalCredits >= costs.image)
        }
    }, [data]);

    useEffect(() => {
        setLockedGeneration(null)
        setInpaintingImage(false)
    }, []);

    return (
        <Stack direction="column" px="4" py="4" gap="6">
            <ProductSwitcher/>
            {
                inpaintingImage
                    ?
                    <VStack spacing={6}>

                        {/*<Image boxSize='100px' src={inpaintingMaskImage}/>*/}

                        <Divider borderColor='gray.600'/>
                        <VStack>
                            <Text align='center'>PAINTED AREA PROMPT</Text>
                            <Text align='center' fontSize='xs'>You can only enter a prompt for the painted area</Text>
                        </VStack>
                        <Prompt
                            prompt={inpaintingPrompt}
                            setPrompt={setInpaintingPrompt}
                            placeholder="Describe how you'd like to change the image in the painted area."
                        />
                    </VStack>
                    :
                    <>
                        {/*<Stack direction='row'>*/}
                        {/*    <Image boxSize='100px' src={outlineImage}/>*/}
                        {/*    <Image boxSize='100px' src={depthImage}/>*/}
                        {/*    <Image boxSize='100px' src={maskImage}/>*/}
                        {/*</Stack>*/}

                        <VStack spacing={6}>
                            <Divider borderColor='gray.600'/>
                            <Text align='left'>IMAGE FORMAT</Text>
                            <ImageFormat/>
                        </VStack>

                        <VStack spacing={6}>
                            <Divider borderColor='gray.600'/>
                            <Text align='left'>PRODUCT FRAMING</Text>
                            <ProductPositioner/>
                        </VStack>

                        <VStack spacing={6}>
                            <Divider borderColor='gray.600'/>
                            <Text align='left'>PRODUCT PROMPT</Text>
                            <Prompt
                                prompt={productPrompt}
                                setPrompt={setProductPrompt}
                                placeholder="Describe how you'd like to see the product in the image."
                            />
                        </VStack>

                        <VStack spacing={6}>
                            <Divider borderColor='gray.600'/>
                            <Text align='left'>BACKGROUND INSPIRATION</Text>
                            <GuidanceImage
                                guidanceImage={bgGuidanceImage}
                                setGuidanceImage={setBgGuidanceImage}
                                onFileDropped={onBgGuidanceImageAdded}
                            />
                        </VStack>

                        <VStack spacing={6}>
                            <Divider borderColor='gray.600'/>
                            <Text align='left'>BACKGROUND PROMPT</Text>
                            <Prompt
                                prompt={bgPrompt}
                                setPrompt={setBgPrompt}
                                placeholder='Any additional details you would like to add to the background'
                            />
                        </VStack>
                    </>
            }

            <VStack spacing={6}>
                <Divider borderColor='gray.600'/>
                <Tooltip
                    label={hasEnoughCredits ?
                        ''
                        :
                        "You don't have enough credits to generate an image. Please purchase more credits."
                    }
                    placement='top'
                >
                    <Box bg='transparent' border='4px' borderColor='white' rounded='full' p={0.5} w='60%'>
                        <Button
                            isDisabled={!hasEnoughCredits || !selectedProduct}
                            border='2px'
                            borderColor='white'
                            color='white'
                            w='100%'
                            size='lg'
                            rounded='full'
                            bg="#101214"
                            _hover={{bgGradient: "linear(to-r, pink.500, purple.500)"}}
                            onClick={() => {
                                if (inpaintingImage) {
                                    // console.log("starting inpaint generation")
                                    setShouldCaptureInpaintingMask(true)
                                } else {
                                    // console.log("starting regular generation")
                                    setShouldCaptureControlImages(true)
                                }
                                setShouldGenerateImage(true)
                            }}
                            isLoading={isSubmittingToQueue}
                        >
                            <Text bgGradient="linear(to-r, yellow.300, pink.300)" bgClip='text'
                                  fontWeight={900}>IMAGINATE!</Text>
                        </Button>
                    </Box>
                </Tooltip>
            </VStack>

        </Stack>
    )
}
