import {Box, Button, Image} from "@chakra-ui/react";
import CanvasDraw from "@win11react/react-canvas-draw";
import {useEffect, useRef, useState} from "react";
import {useStudioStore} from "@krfts/app-studio-content/state";

export const InpaintEditor = ({imageURL}) => {

    const inpaintingImage = useStudioStore((state) => state.inpaintingImage);
    const setInpaintingImage = useStudioStore((state) => state.setInpaintingImage);

    const isPainting = useStudioStore((state) => state.isPainting);
    const setIsPainting = useStudioStore((state) => state.setIsPainting);

    const shouldCaptureInpaintingMask = useStudioStore((state) => state.shouldCaptureInpaintingMask)
    const inpaintingMaskImage = useStudioStore((state) => state.inpaintingMaskImage)
    const setShouldCaptureInpaintingMask = useStudioStore((state) => state.setShouldCaptureInpaintingMask)

    const setInpaintingMaskImage = useStudioStore((state) => state.setInpaintingMaskImage)
    const setInpaintingMaskCaptured = useStudioStore((state) => state.setInpaintingMaskCaptured)


    const canvasDrawRef = useRef(null);

    useEffect(() => {
        if (shouldCaptureInpaintingMask) {
            // console.log('capturing inpainting mask')
            setInpaintingMaskImage(canvasDrawRef.current.getDataURL('png', false, 'white'))
            setInpaintingMaskCaptured(true)
            setShouldCaptureInpaintingMask(false)
            // canvasDrawRef.current.resetView()
            // canvasDrawRef.current.clear()
        }
    }, [shouldCaptureInpaintingMask]);

    return (
        <Box
            position='absolute'
            top={0}
            w='100%'
            h='100%'
            bgImage={imageURL}
            bgSize='cover'
            onMouseDown={() => setIsPainting(true)}
            onMouseUp={() => setIsPainting(false)}
        >
            <CanvasDraw
                ref={canvasDrawRef}
                imgSrc={imageURL}
                brushRadius={20}
                brushColor='#000000'
                canvasWidth='100%'
                canvasHeight='100%'
            />
        </Box>

    )
}