import {
    Box,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerCloseButton,
    DrawerOverlay,
    useDisclosure,
    Text,
    IconButton,
    Icon,
    Center,
    HStack, Button, VStack,
} from '@chakra-ui/react'
import {GenerationControls} from "./ui/generation-controls";
import {GenerationsList} from "./ui/generations-list";
import {useQuery} from "@tanstack/react-query";
import {getProducts} from "@krfts/app-data";
import {LoadingIndicator} from "@krfts/core-ui-library";
import {useEffect} from "react";
import {useStudioStore} from "./state";
import {isMobile} from "react-device-detect";
import {IoOptionsOutline, IoDocumentLockOutline, IoChevronBack} from "react-icons/io5";

export const StudioContent = () => {

    const setSelectedProduct = useStudioStore((state) => state.setSelectedProduct);
    const selectedProduct = useStudioStore((state) => state.selectedProduct);
    const lockedGeneration = useStudioStore((state) => state.lockedGeneration);
    const setShouldDisplayLockedGeneration = useStudioStore((state) => state.setShouldDisplayLockedGeneration);
    const {isPending, data} = useQuery({queryKey: ['products'], queryFn: getProducts})

    useEffect(() => {
        if (!isPending) {
            let _selectedProduct = null
            if (!lockedGeneration) {
                if (selectedProduct) _selectedProduct = selectedProduct
                else {
                    _selectedProduct = data[0]
                    if (_selectedProduct) {
                        if (_selectedProduct.trainings[_selectedProduct.active_training].lora_training.status !== 'DONE' ||
                            _selectedProduct.trainings[_selectedProduct.active_training].model_3D_generation.status !== 'DONE') {
                            _selectedProduct = null
                        }
                    }
                }
            } else {
                _selectedProduct = data.find(product => product.id === lockedGeneration.product_id)
            }
            setSelectedProduct(_selectedProduct)
        }
    }, [isPending, lockedGeneration]);

    const {isOpen, onOpen, onClose} = useDisclosure()

    const renderContent = () => {
        if (isPending) {
            return (<LoadingIndicator/>)
        } else {
            return (
                <>
                    {!isMobile ?
                        <Box w="400px" bg="gray.900" color="white" fontSize="sm" overflow='auto' position='relative'
                             sx={{
                                 '&::-webkit-scrollbar': {
                                     width: '4px',
                                 },
                                 '&::-webkit-scrollbar-track': {
                                     width: '6px',
                                 },
                                 '&::-webkit-scrollbar-thumb': {
                                     background: "gray",
                                     borderRadius: '24px',
                                 }
                             }}
                        >
                            <GenerationControls/>
                            {!selectedProduct ?
                                <Box position='absolute' w='100%' h='100%' bg='blackAlpha.700' top={0} p={8}>
                                    <Center w='100%' h='100%'>
                                        <Text align='center' fontSize='lg'>You don't have any products that have
                                            finished
                                            training yet</Text>
                                    </Center>
                                </Box> : null}
                        </Box>
                        :
                        <IconButton
                            color='black'
                            size='lg'
                            variant='ghost'
                            onClick={onOpen}
                            position='absolute'
                            aria-label='Open settings'
                            icon={<IoOptionsOutline size={28}/>}
                            zIndex={1000}
                        />
                    }


                    <Box bg={lockedGeneration ? 'blackAlpha.700' : 'white'} flex="1" p="6" overflow='auto'
                         sx={{
                             '&::-webkit-scrollbar': {
                                 width: '4px',
                             },
                             '&::-webkit-scrollbar-track': {
                                 width: '6px',
                             },
                             '&::-webkit-scrollbar-thumb': {
                                 background: "gray",
                                 borderRadius: '24px',
                             }
                         }}
                         position='relative'>
                        <GenerationsList/>

                        {lockedGeneration ? <Box
                            bg='blackAlpha.500'
                            rounded='md'
                            w='50%'
                            h='47px'
                            position='absolute'
                            left='50%'
                            top={1}
                            transform='translate(-50%);'
                            cursor='pointer'
                            onClick={() => setShouldDisplayLockedGeneration(true)}
                        >
                            <Center h='100%'>
                                <VStack gap={0}>
                                    <HStack>
                                        <Icon as={IoDocumentLockOutline} boxSize='20px' color='white'/>
                                        <Text color='white' fontSize='md'>Locked for iteration</Text>
                                    </HStack>
                                    <Text fontWeight={100} fontSize='2xs' color={"whiteAlpha.500"}>Click to go back to
                                        locked image</Text>
                                </VStack>
                            </Center>
                        </Box> : null}

                    </Box>

                    <Drawer
                        isOpen={isOpen}
                        onClose={onClose}
                        placement='left'
                        size='lg'
                    >
                        <DrawerOverlay/>
                        <DrawerContent bg="gray.900" mt='56px' pb={6}>
                            <DrawerCloseButton color="white"/>


                            <DrawerBody color="white"
                                        fontSize="sm">
                                <GenerationControls mobileDrawerCallback={() => onClose()}/>
                            </DrawerBody>

                        </DrawerContent>
                    </Drawer>

                </>
            )
        }
    }

    return (
        renderContent()
    )
}