import {
    Box, Heading, Button, Stack, HStack, Center, Input, Textarea, Spinner
} from "@chakra-ui/react";
import {TextBox} from "@krfts/core-text-box";
import {BgCarousel} from './bg-carousel'
import {useAuthStore} from "@krfts/app-state";
import {useNavigate} from "react-router";
import {Logo} from "@krfts/app-logo"
import {useState} from "react";
import {authenticateBetaCode, registerForBeta} from "./data";

export const LandingContent = () => {

    const [betaCode, setBetaCode] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [registering, setRegistering] = useState(false)
    const [registered, setRegistered] = useState(false)

    const [betaing, setBetaing] = useState(false)

    const setBetaAuthorized = useAuthStore((state) => state.setBetaAuthorized);
    const loading = useAuthStore((state) => state.loading);

    const navigate = useNavigate()

    const onBetaCodeSubmit = async () => {
        setBetaing(true)
        const res = await authenticateBetaCode(betaCode)
        if (res) {
            localStorage.setItem("betaAuth", "true");
            setBetaAuthorized(true)
            setBetaing(false)
            navigate('/')
        }
    }

    const onEmailSubmit = async () => {
        setRegistering(true)
        const res = await registerForBeta(email, message)
        setRegistering(false)
        setRegistered(true)
    }

    if (loading) {
        return (
            <Center w='100%' h='100%' bg='black'>
                <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='white.500'
                    size='xl'
                />
            </Center>
        );
    }

    return (
        <>
            <Center color='white' w='100%'>
                <Stack direction='column' px={{base: 10, md: 40}} w='100%' align='center' spacing={10}>
                    <Box
                        bg="black"
                        p={1}
                    >
                        <Logo w={{base: '300px', md: '450px'}}/>
                    </Box>

                    <Box mt="10" w={{base: '95%', md: '60%'}} align='center' bg='black' pb={4}>
                        <Stack direction='column' spacing={6}>
                            <TextBox
                                text={!registered ? 'We are working hard to get things ready. If you would like beta access, please enter your email below along with an optional message.'
                                    :
                                    'Thank you - we will be in touch soon!'
                                }
                            />
                            <Center>
                                {!registered ?
                                    <Stack direction='column' w={{base: '80%', md: '50%'}} align='center' spacing={4}>
                                        <Input type='email' size='sm' bg='white' rounded='none' color='black'
                                               placeholder='Enter your email address'
                                               value={email}
                                               onChange={e => setEmail(e.target.value)}
                                        />
                                        <Textarea size='sm' bg='white' color='black' rounded='none'
                                                  placeholder='Message'
                                                  value={message}
                                                  onChange={e => setMessage(e.target.value)}
                                        />
                                        <Button size='sm' bg='yellow.300' w='50%'
                                                onClick={() => onEmailSubmit()}
                                                isLoading={registering}
                                                loadingText='Sending...'
                                                _hover={{bg: 'yellow.100'}}
                                        >
                                            Send
                                        </Button>
                                    </Stack> : null}
                            </Center>
                        </Stack>
                    </Box>

                    <Box mt="10" w={{base: '95%', md: '60%'}} align='center' bg='black' pb={4}>
                        <Stack direction='column' spacing={6}>
                            <TextBox
                                text='If you already have a beta code, please enter it below to enter Imaginate.'
                            />
                            <Center>
                                <Stack direction={{base: 'column', md: 'row'}} w={{base: '80%', md: '40%'}}>
                                    <Input size='sm' bg='white' rounded='none' color='black'
                                           placeholder='Enter your beta code'
                                           value={betaCode}
                                           onChange={e => setBetaCode(e.target.value)}
                                    />
                                    <Button
                                        size='sm'
                                        bg='yellow.300'
                                        _hover={{bg: 'yellow.100'}}
                                        isLoading={betaing}
                                        onClick={() => onBetaCodeSubmit()}
                                    >
                                        Enter
                                    </Button>
                                </Stack>
                            </Center>
                        </Stack>
                    </Box>

                </Stack>

            </Center>
            <BgCarousel fullHeight/>
        </>);
};