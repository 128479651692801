import { Stack } from '@chakra-ui/react'

import {ModelImage} from "./model-image";
import leftView from "../../../assets/left-view.png";
import rightView from "../../../assets/right-view.png";
import frontView from "../../../assets/front-view.png";
import backView from "../../../assets/back-view.png";
import topView from "../../../assets/top-view.png";

export const ModelImages = (props) => {
    return (
            <Stack direction={{base:'column', md:'row'}} spacing={{base:"2", md:"4"}}>
                <ModelImage
                    image={props.modelImageFront}
                    onFileDrop={props.setImage}
                    onImageRemoved={props.setModelImageFront}
                    title='Front View'
                    icon={frontView}
                    allowRemove={props.allowRemove || false}
                />
                <ModelImage
                    image={props.modelImageLeft}
                    onFileDrop={props.setImage}
                    onImageRemoved={props.setModelImageLeft}
                    title='Left View'
                    icon={leftView}
                    allowRemove={props.allowRemove || false}
                />
                <ModelImage
                    image={props.modelImageRight}
                    onFileDrop={props.setImage}
                    onImageRemoved={props.setModelImageRight}
                    title='Right View'
                    icon={rightView}
                    allowRemove={props.allowRemove || false}
                />
                <ModelImage
                    image={props.modelImageBack}
                    onFileDrop={props.setImage}
                    onImageRemoved={props.setModelImageBack}
                    title='Back View'
                    icon={backView}
                    allowRemove={props.allowRemove || false}
                />
                <ModelImage
                    image={props.modelImageTop}
                    onFileDrop={props.setImage}
                    onImageRemoved={props.setModelImageTop}
                    title='Top View'
                    icon={topView}
                    allowRemove={props.allowRemove || false}
                />
            </Stack>
    )
}