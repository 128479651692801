import {Box, Flex} from '@chakra-ui/react'
import {SectionTabs} from "./ui/section-tabs";
import {BillingDashboard} from "./ui/billing-dashboard";
import {UserProfile} from "./ui/user-profile";
import {useAuthStore} from "@krfts/app-state";
import bg_image from './assets/bg_image.jpg'
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {getUser} from "@krfts/app-auth-modal/data/auth-api";

export const UserAccountContent = ({tab}) => {

    const authStore = useAuthStore()

    const queryClient = useQueryClient()

    const {isPending, isError, data, error} = useQuery(
        {
            queryKey: ['user', authStore.currentUser.uid],
            queryFn: () => getUser(authStore.currentUser)
        })

    // console.log(data)
    if (isPending) return null

    return (
        <>
            <Box
                w='100%'
                h='100%'
                bgImage={bg_image}
                bgSize='cover'
                overflow='auto'
                position='relative'
                boxShadow='inset 0 0 0 2000px rgba(0, 0, 0, 0.7)'
            >

                <Box
                    maxW="5xl"
                    mx="auto"
                    px={{base: '4', md: '8', lg: '12'}}
                    py={{base: '6', md: '8', lg: '6'}}
                    color='white'
                    bg='black'
                    mt={20}
                >
                    <Flex h="full">
                        <SectionTabs
                            activeTab={tab}
                            sections={[
                                {
                                    title: "Profile",
                                    component: <UserProfile user={data}/>
                                },
                                {
                                    title: "Plans & Billing",
                                    component: <BillingDashboard user={data}/>
                                }
                            ]}
                        />
                    </Flex>
                </Box>
            </Box>

        </>
    )
}