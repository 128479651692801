import {AspectRatio, Stack, Center, Box} from "@chakra-ui/react";
import {ModelViewer} from "@krfts/core-model-viewer";
import {ModelViewerStudioScene} from "../model-viewer-studio-scene";
import {calculateAspectRatio} from "@krfts/core-utils";
import {useStudioStore} from "../../../state";
import {useEffect, useState} from "react";
import {createDownloadURL} from "@krfts/core-utils";
import {TransformControls} from "./transform-controls";
import {Product3DView} from "./product-3d-view";

export const ProductPositioner = () => {

    const [modelURL, setModelURL] = useState(null)

    const imageFormat = useStudioStore((state) => state.imageFormat)
    const selectedProduct = useStudioStore((state) => state.selectedProduct)
    const shouldResetTransform = useStudioStore((state) => state.shouldResetTransform)

    useEffect(() => {
        const getModelURL = async (isLegacy) => {
            if (isLegacy) {
                setModelURL(await createDownloadURL(selectedProduct.product_3D_model ? selectedProduct.product_3D_model.file : selectedProduct.model_3D.file))
            } else {
                const activeTraining = selectedProduct.active_training
                setModelURL(await createDownloadURL(selectedProduct.product_3D_model ? selectedProduct.product_3D_model.file : selectedProduct.trainings[activeTraining].model_3D_generation.file))
            }
        }

        if (selectedProduct && selectedProduct.trainings) getModelURL(false)
        else if (selectedProduct) getModelURL(true)
    }, [selectedProduct])

    return (
        <Stack direction='column' spacing={0} w='100%' h='100%'>
            <AspectRatio ratio={calculateAspectRatio(imageFormat)} w='100%'>
                <Box w='100%' h='100%'>
                    {modelURL ? <Product3DView glbURL={modelURL}/> : null}
                </Box>
            </AspectRatio>
            <Center>
                <TransformControls/>
            </Center>
        </Stack>
    )
}
