import {Layout} from '@krfts/core-layout'
import {AuthModal} from "@krfts/app-auth-modal";
import {ServersDownContent} from "@krfts/app-servers-down-content";

export const ServersDown = () => {

    return (
        <Layout
            topBar={null}
            page={<ServersDownContent/>}
            footer={null}
            authModal={<AuthModal/>}
        />
    )
}