import {Box, Button, Container, Icon, SimpleGrid, Stack} from "@chakra-ui/react";
import {Stat} from "./stat";
import {useQuery} from "@tanstack/react-query";
import {getDataDump} from "../../data";
import {
    IoFilmOutline,
    IoImageOutline,
    IoPersonOutline,
    IoCubeOutline,
    IoLayersOutline,
    IoAlertOutline
} from "react-icons/io5";

export const DataBoard = () => {

    const {isPending, isError, data, error} =
        useQuery(
            {
                queryKey: ['dataDump'],
                queryFn: getDataDump
            })

    console.log(data)

    if (isPending) return null

    return(
        <Box as="section" py={{ base: '4', md: '8' }}>
            <Container maxW="container.lg">
                <SimpleGrid columns={{ base: 1, md: 3 }} gap={{ base: '5', md: '6' }}>
                    <Stat
                        label="Registered users"
                        value={data.users}
                        icon={IoPersonOutline}
                    />
                    <Stat
                        label="Images generated"
                        value={data.images}
                        icon={IoImageOutline}
                    />
                    <Stat
                        label="Videos generated"
                        value={data.videos}
                        icon={IoFilmOutline}
                    />
                    <Stat
                        label="Total Products"
                        value={data.products}
                        icon={IoCubeOutline}
                    />
                    <Stat
                        label="Total Generations"
                        value={data.generations}
                        icon={IoLayersOutline}
                    />
                    <Stat
                        label="Failed generations"
                        value={data.failedGenerations}
                        icon={IoAlertOutline}
                    />
                </SimpleGrid>
            </Container>
        </Box>
    )

}