import { Box, SimpleGrid } from '@chakra-ui/react'
import { ProductCard } from './product-card'

export const ProductGrid = ({products}) => (
    <Box
        maxW="5xl"
        mx="auto"
        px={{ base: '4', md: '6', lg: '6' }}
        py={{ base: '6', md: '6', lg: '6' }}
    >
        <SimpleGrid columns={{ base: 2, sm: 2, lg: 4 }} gap={{ base: '4', lg: '4' }} >
            {products.map((product, i) => (
                <ProductCard key={i} product={product} />
            ))}
        </SimpleGrid>
    </Box>
)