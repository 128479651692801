import React, {useRef, useEffect, useState} from "react";
import {Canvas} from "@react-three/fiber";
import {useGLTF, Stage, OrbitControls} from "@react-three/drei";
import * as THREE from "three";

const GLBModel = ({glbURL, rotOffset, onPivotCentered}) => {
    const {scene} = useGLTF(glbURL);

    const outerGroupRef = useRef();
    const innerGroupRef = useRef()
    const primitiveRef = useRef();

    // const adjustAxis = (scene) => {
    //     // Apply a 90-degree X-axis rotation for Z-up to Y-up conversion
    //     const isLikelyZUp = scene.rotation.x === 0 && scene.rotation.y === 0 && scene.rotation.z === 0;
    //
    //     if (isLikelyZUp) {
    //         scene.rotation.x = -Math.PI / 2; // Convert Z-up to Y-up
    //     }
    //
    //     scene.updateMatrixWorld();
    // }

    useEffect(() => {
        scene.traverse((child) => {
            if (child.isMesh) {
                child.material.metalness = 0;
                child.material.roughness = 1;
                child.material.color = new THREE.Color('gray');
            }
        });

        // adjustAxis(scene)
        // scene.updateMatrixWorld();

        if (primitiveRef.current) {
            console.log('calculating center')
            // Calculate the bounding box of the primitive
            const box = new THREE.Box3().setFromObject(primitiveRef.current);

            if (!box.isEmpty()) {
                // Center of the bounding box
                const center = new THREE.Vector3();
                box.getCenter(center);

                // Reposition the primitive so the model's center aligns with [0, 0, 0]
                primitiveRef.current.position.sub(center);

                // Optional: Log the center for debugging
                console.log("Bounding box center:", center);

                if (onPivotCentered) {
                    onPivotCentered();
                }
            }
        }
    }, [scene]);

    // apply rotation offset
    useEffect(() => {
        outerGroupRef.current.setRotationFromAxisAngle(new THREE.Vector3(1, 0, 0), rotOffset.x); // X rotation
        outerGroupRef.current.rotateOnWorldAxis(new THREE.Vector3(0, 1, 0), rotOffset.y); // Y rotation
        outerGroupRef.current.rotateOnWorldAxis(new THREE.Vector3(0, 0, 1), rotOffset.z);
    }, [rotOffset]);

    // return <primitive object={scene} ref={groupRef} rotation={[rotOffset.x, rotOffset.y, rotOffset.z]}/>;
    return (
        <group ref={outerGroupRef} >
            <primitive object={scene} ref={primitiveRef}/>
        </group>
    )
};


export const Product3DPreview = ({glbURL, rotOffset}) => {
    const [isPivotCentered, setIsPivotCentered] = useState(false);

    const handlePivotCentered = () => {
        // Callback invoked when GLBModel finishes centering
        setIsPivotCentered(true);
    };

    return (
        <Canvas
            style={{width: "100%", height: "100%", background: "black"}}
        >
            {/*<axesHelper args={[5]}/>*/}
            <directionalLight position={[0, 1, 0]} intensity={1}/>

            {isPivotCentered && (
                <Stage
                    preset="soft"
                    adjustCamera={1.5}
                    intensity={0.5}
                    shadows={false}
                    environment="studio"
                >
                    <GLBModel
                        glbURL={glbURL}
                        rotOffset={rotOffset}
                        onPivotCentered={handlePivotCentered} // Pass callback to trigger completion
                    />
                </Stage>
            )}
            {!isPivotCentered && (
                <GLBModel
                    glbURL={glbURL}
                    rotOffset={rotOffset}
                    onPivotCentered={handlePivotCentered}
                />
            )}

            <OrbitControls makeDefault enablePan={false} enableRotate={false} enableZoom={false} autoRotate={true}/>
        </Canvas>
    );
};